import moment from "moment";
import { useState } from "react";

import { BsFillTagFill, BsClockFill } from "react-icons/bs";

import { FaEthereum } from "react-icons/fa";
import Loader from "../Loader/Loader";

import "./index.css";

interface IListingFormProps {
  handleListNft: Function;
  showLoader: boolean;
}

const NFTListingForm = ({ handleListNft, showLoader }: IListingFormProps) => {
  const [listingModeActive, setListingModeActive] = useState(0);
  const [newNftPrice, setNewNftPrice] = useState(0);
  const [nftAuctionDeadline, setNftAuctionDeadline] = useState(0);
  const [isAuctionNft, setIsAuctionNft] = useState(false);

  return (
    <div className="nftListFormSection">
      <h4>Select Listing Modes</h4>
      <div className="nftSaleModeFlexWrap">
        <div
          className={
            listingModeActive === 0 ? "nftSaleModeActiveDiv" : "nftSaleModeDiv"
          }
          onClick={() => {
            setListingModeActive(0);
            setIsAuctionNft(false);
          }}
        >
          <BsFillTagFill size={25} />
          <p>Fixed Sale Price</p>
        </div>
        <div
          className={
            listingModeActive === 1 ? "nftSaleModeActiveDiv" : "nftSaleModeDiv"
          }
          onClick={() => {
            setListingModeActive(1);
            setIsAuctionNft(true);
          }}
        >
          <BsClockFill size={25} />
          <p>Timed Auctions</p>
        </div>
      </div>
      <br />
      <br />
      {listingModeActive === 0 ? (
        <div className="inputDiv">
          <p>NFT Price</p>
          <input
            type="number"
            placeholder=""
            onChange={(event) => {
              console.log(newNftPrice);
              setNewNftPrice(parseFloat(event.target.value));
            }}
          />
        </div>
      ) : (
        ""
      )}

      {listingModeActive === 1 ? (
        <div className="inputDiv">
          <p>Auction Base Price</p>
          <input
            type="number"
            placeholder=""
            onChange={(event) => {
              setNewNftPrice(parseFloat(event.target.value));
            }}
          />
        </div>
      ) : (
        ""
      )}
      <br />
      <br />
      {listingModeActive === 1 ? (
        <div className="inputDiv">
          <p>Number of Days for Auction</p>
          <input
            type="number"
            placeholder=""
            onChange={(event) => {
              const parsedDays = moment.duration(
                parseInt(event.target.value),
                "days"
              );

              const daysInMilliseconds = parsedDays.asMilliseconds();

              const deadline = Date.now() + daysInMilliseconds;

              setNftAuctionDeadline(deadline);
            }}
          />
        </div>
      ) : (
        ""
      )}

      <br />
      <br />

      <button
        className="button_style_one"
        onClick={() => {
          handleListNft(nftAuctionDeadline, isAuctionNft, newNftPrice);
        }}
      >
        {/* <FaEthereum  size={30} color='#343444' /> */}
        <p>List NFT</p>
      </button>
      {showLoader ? <Loader /> : ""}
    </div>
  );
};

export default NFTListingForm;
