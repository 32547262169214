import { createContext, ReactNode, useState  } from "react";
import { NftItemAndAuctionAndUser } from "../types/dto/response";

interface IState{
    connected:boolean,
    userAddress:string,
    showNotification : boolean,
    notificationMessage : string,
    notificationType : boolean
    setConnected:Function,
    setUserAddress : Function,
    setShowNotification : Function,
    setNotificationMessage : Function,
    setNotificationType : Function,
    ethereumPrice : number,
    setEthereumPrice : Function
    explorePageCacheTrack : ExplorePageCacheTrack | null,
    setExplorePageCacheTrack : Function
    homePageDataCache : HomePageDataCache | null,
    setHomePageDataCache : Function
}


interface ExplorePageCacheTrack{
    allNftData : NftItemAndAuctionAndUser[]
    allNFTDataView : NftItemAndAuctionAndUser[]
    positionInPage : number,
    searchCursor : number
}

interface HomePageDataCache {
    liveAuctions : NftItemAndAuctionAndUser[],
    topNftPicks : NftItemAndAuctionAndUser[]
}

interface Props{
    children : ReactNode
}

const initialState:IState = {
    connected : false,
    userAddress : "",
    showNotification : false,
    notificationMessage  : "",
    notificationType: false,
    setConnected : ()=>{},
    setUserAddress : ()=>{},
    setShowNotification : ()=>{},
    setNotificationMessage : ()=>{},
    setNotificationType : ()=>{},
    ethereumPrice : 0,
    setEthereumPrice : ()=>{},
    explorePageCacheTrack : null,
    setExplorePageCacheTrack : ()=>{},
    homePageDataCache : null,
    setHomePageDataCache : ()=>{}
}

export const AppContext =  createContext(initialState)


const AppProvider = ({children}:Props)=>{

    const [connected, setConnected] = useState(false)
    const [userAddress, setUserAddress] = useState("")
    const [showNotification, setShowNotification]  =  useState<boolean>(false)
    const [notificationMessage, setNotificationMessage] = useState<string>("")
    const [notificationType, setNotificationType] =  useState<boolean>(false)
    const [ethereumPrice, setEthereumPrice] = useState<number>(0)
    const [explorePageCacheTrack, setExplorePageCacheTrack] = useState<ExplorePageCacheTrack | null >(null)
    const [homePageDataCache, setHomePageDataCache] = useState<HomePageDataCache |null >(null)
    return(
        <AppContext.Provider
        value={{
            connected,
            setConnected,
            userAddress,
            setUserAddress,
            showNotification,
            setShowNotification,
            notificationMessage,
            setNotificationMessage,
            notificationType,
            setNotificationType,
            ethereumPrice,
            setEthereumPrice,
            explorePageCacheTrack,
            setExplorePageCacheTrack,
            homePageDataCache,
            setHomePageDataCache

        }}
        >
            {children}
        </AppContext.Provider>
    )
}

export type {ExplorePageCacheTrack, HomePageDataCache}

export default AppProvider