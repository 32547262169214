import AWS from 'aws-sdk'

AWS.config.update({
    accessKeyId : process.env.REACT_APP_ACCESS_KEY,
    secretAccessKey :process.env.REACT_APP_SECRET_ACCESS_KEY
})

const bucketName:string = process.env.REACT_APP_S3_BUCKET != undefined ? process.env.REACT_APP_S3_BUCKET :""

const region:string  = process.env.REACT_APP_S3_REGION != undefined ? process.env.REACT_APP_S3_REGION :""

const myBucket = new AWS.S3({
    region:region,
    params:{Bucket:bucketName}
})


const uploadImageToS3 =  (folderName:string, fileName:string, file:File):Promise<string>=>{

    return new Promise(async (resolve, reject)=>{

        const initialKey  =  `${encodeURIComponent(folderName)}/`

        const fileNameField = initialKey + fileName
    
        const upload = new AWS.S3.ManagedUpload({
            params: {
                Bucket: bucketName ,
                Key: fileNameField,
                Body: file
              }
        })

        const uploadData =  await upload.promise()

        console.log(uploadData)
        resolve(uploadData.Location)
    })
    
}

export {uploadImageToS3}