import { API_URL } from "../../constants/main"
import {  IGetNftItemByContractAndId, IGetProfilesOfUsersInvolvedInNft, IImportNFTToMarketplaceReq, IListNftReq, IUpdateNFTDataAfterSaleRequest } from "../../types/dto/requests"
import { IDeListNftRes, IGetNftItemFromMoralis, IGetNtfItemFromDBRes, IGetProfileOfUsersInvolvedInNftRes, IImportNftToMarketplaceRes, IListNftRes, IUpdateNFTDataAfterSaleRes } from "../../types/dto/response"

const getNftItemFromDBWithContractAddressAndId = (contractAddress: string, nftTokenId:number ):Promise<IGetNtfItemFromDBRes>=>{


    return new Promise(async (resolve,reject)=>{

        const apiReqObject:IGetNftItemByContractAndId  ={
            contractAddress :contractAddress,
            tokenId : nftTokenId
        }

        const nftItemReq  =  await fetch(`${API_URL}/nft/getOneWithContractAddressAndId`, {
            method : 'POST',
            headers:{
                'Content-type' : 'application/json'
            },
            body : JSON.stringify(apiReqObject)
        })

        const nftItemres: IGetNtfItemFromDBRes =  await nftItemReq.json()
        resolve(nftItemres)
    })


}

const getNftItemFromMoralis = (contractAddress: string, nftTokenId:number):Promise<IGetNftItemFromMoralis>=>{

    return new Promise(async(resolve,reject)=>{
        const apiReqObject:IGetNftItemByContractAndId  ={
            contractAddress :contractAddress,
            tokenId : nftTokenId
        }
    
        const getNftItem =  await fetch(`${API_URL}/externalData/getNftData`, {
            method : 'POST',
            headers : {
                'Content-type' : 'application/json'
            },
            body : JSON.stringify(apiReqObject)
        })

        const nftItem:IGetNftItemFromMoralis = await getNftItem.json()
        resolve(nftItem)
    })

}


const importNftToMarketPlace = (nftName:string, nftContractId : number, nftPrice: number, nftOwner : string, nftCreator : string, userAddress : string, nftHistory:Array<any>, nftType : string, nftMediaType : string, nftImageLink : string, isAuctionNft:boolean, collectionAddress : string, nftDescription : string, auctionDeadline : number, nftMediaLink : string):Promise<IImportNftToMarketplaceRes>  =>{
    return new Promise(async (resolve,reject)=>{
        const requestObject:IImportNFTToMarketplaceReq = {
            auctionDeadline : auctionDeadline,
            collectionAddress : collectionAddress,
            contractId  : nftContractId,
            isAuctionNft : isAuctionNft,
            nftCreator : nftCreator,
            nftDescription : nftDescription,
            nftHistory : [],
            nftImageLink : nftImageLink,
            nftMediaType : nftMediaType,
            nftMediaLink : nftMediaLink,
            nftName : nftName,
            nftOwner : nftOwner,
            nftPrice : nftPrice,
            nftType : nftType,
            userAddress  : userAddress
        }

        console.log(requestObject)

        const requestToCreateNft = await fetch(`${API_URL}/nft/create`, {
            method :'POST',
            headers : {
                'Content-type' : 'application/json',
            },
            body : JSON.stringify(requestObject),
            credentials :'include',
        })

        const nftCreatedResponse:IImportNftToMarketplaceRes = await requestToCreateNft.json()
        resolve(nftCreatedResponse)


    })
} 

const listNftInMarketPlace  = (auctionDeadline: number, isNftAuctionNft : boolean, nftBasePrice: number, nftID:string, userAddress : string, marketPlaceId : number ):Promise<IListNftRes>=>{

    return new Promise(async(resolve,reject)=>{
        const listNftRequestObject :IListNftReq = {
            auctionDeadline : auctionDeadline,
            isListingAuction : isNftAuctionNft,
            nftBasePrice : nftBasePrice,
            nftId : nftID,
            userAddress : userAddress,
        } 

        const listNftReq = await fetch(`${API_URL}/nft/list`, {
            method : 'POST',
            headers : {
                'Content-type' :'application/json',
            },
            credentials :'include',
            body : JSON.stringify(listNftRequestObject)
        })

        const responseFromListNft:IListNftRes = await listNftReq.json()
        resolve(responseFromListNft)
    })

}

const unListNftItem = (nftId : string, userAddress : string, isNftAuctionNft : boolean):Promise<IDeListNftRes>=>{
    return new Promise(async(resolve,reject)=>{
        const requestObject:any = {
            nftId : nftId,
            userAddress : userAddress,
            wasListingAuction : isNftAuctionNft
        }

        const unlistNFTreq = await fetch(`${API_URL}/nft/delist`, {
            method :'POST',
            headers : {
                'Content-type' : 'application/json'
            },
            credentials :'include',
            body : JSON.stringify(requestObject)
        })

        const unlistNftResponse:IDeListNftRes = await unlistNFTreq.json()
        resolve(unlistNftResponse)
    })
}


const getUsersInvolvedInNft = (nftId : string) : Promise<IGetProfileOfUsersInvolvedInNftRes>=>{
    return new Promise(async(resolve, reject)=>{
        const requestObject:IGetProfilesOfUsersInvolvedInNft = {
            nftId : nftId
        }

        const fetchApi =  await fetch(`${API_URL}/nft/getAllUsersInvolvedInNftActivity`, {
            method : 'POST',
            headers : {
                'Content-Type' : 'application/json'
            },
            body : JSON.stringify(requestObject)
        })

        const jsonResponse:IGetProfileOfUsersInvolvedInNftRes  = await fetchApi.json()
        resolve(jsonResponse)
    })
} 



const UpdateNftAfterPurchase = (nftId:string, previousOwner:string, newOwner:string, nftSoldPrice:number, wasNFTAuction:boolean):Promise<IUpdateNFTDataAfterSaleRes>=>{
    return new Promise(async(resolve,reject)=>{
        const requestBody:IUpdateNFTDataAfterSaleRequest = {
             newOwner : newOwner,
             nftId : nftId,
             nftSoldPrice : nftSoldPrice, 
             previousOwner : previousOwner,
             wasItemAuction : wasNFTAuction
        }

        const fetchApi =  await fetch(`${API_URL}/nft/updateNftAfterSale`, {
            method : 'POST',
            headers : {
                'Content-Type' : 'application/json',
            },
            credentials :'include',
            body : JSON.stringify(requestBody)
        })

        const responseData:IUpdateNFTDataAfterSaleRes = await fetchApi.json()
        resolve(responseData)
    })
}

export {getNftItemFromDBWithContractAddressAndId,
    getNftItemFromMoralis,
    importNftToMarketPlace,
    listNftInMarketPlace,
    unListNftItem,
    getUsersInvolvedInNft,
    UpdateNftAfterPurchase
}
