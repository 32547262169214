// import {useState} from 'react'
import "./index.css";
import { IoIosRocket } from "react-icons/io";
import { MdOutlineLibraryBooks } from "react-icons/md";
import WaifuElf from "../../assets/firstImg.png";
import NFTCard from "../../components/NftCard";
import { fetchHomePageFeedData } from "../../apiFunctions/feed_api/main";
import React, { useContext, useEffect, useState } from "react";
import { NftItemAndAuctionAndUser } from "../../types/dto/response";
import { Link } from "react-router-dom";
import { AppContext, HomePageDataCache } from "../../context/context";

const HomePage = () => {
  const [topAuctions, setTopAuctions] = useState<NftItemAndAuctionAndUser[]>(
    []
  );
  const [todayNFTPicks, setTodayNFTPicks] = useState<
    NftItemAndAuctionAndUser[]
  >([]);

  const { homePageDataCache, setHomePageDataCache } = useContext(AppContext);

  const handleGetHomePageData = async () => {
    if (homePageDataCache === null) {
      const homePageData = await fetchHomePageFeedData();
      console.log(homePageData);
      if (homePageData.success) {
        const homePageCache: HomePageDataCache = {
          liveAuctions: homePageData.data.topAuctions,
          topNftPicks: homePageData.data.nftPicks,
        };

        setHomePageDataCache(homePageCache);

        setTopAuctions(homePageData.data.topAuctions);
        setTodayNFTPicks(homePageData.data.nftPicks);
      }
    } else {
      setTopAuctions(homePageDataCache.liveAuctions);
      setTodayNFTPicks(homePageDataCache.topNftPicks);
    }
  };

  useEffect(() => {
    handleGetHomePageData();
  }, []);

  return (
    <div>
      <br />
      <br />
      <br />
      <div className="hero_section">
        <div className="hero_text">
          <h2> Discover and Collect Dogechain NFT's</h2>
          <p style={{ marginTop: "-25px" }}>
            The First Marketplace & Launchpad on Dogechain{" "}
          </p>
          <br />

          <div className="action_hero_section">
            <Link to={"/explore"} style={{ textDecoration: "none" }}>
              <button className="action_btn_hero-trans">
                <IoIosRocket color="white" size={30} />
                <p>Explore</p>
              </button>
            </Link>
            <Link to={"/profile"} style={{ textDecoration: "none" }}>
              <button className="action_btn_hero-white">
                <MdOutlineLibraryBooks color="#5142FC" size={30} />
                List
              </button>
            </Link>
          </div>
        </div>
        <div className="image_section">
          <img src={WaifuElf} alt="" />
        </div>
      </div>
      <div className="live_auction_section">
        <h2>Live Auctions</h2>
        <div
          className="live_auction_section_flex"
          style={{ marginTop: "50px" }}
        >
          {topAuctions.map((auctionNFT, index) => {
            return (
              <React.Fragment key={index}>
                <NFTCard
                  nftOwnerAddress={auctionNFT.NftItem.NFTOwner}
                  isNftInMarketplace={true}
                  nftAnimationUrl={auctionNFT.NftItem.NFTMediaLink}
                  nftContractAddress={auctionNFT.NftItem.CollectionAddress}
                  nftImage={auctionNFT.NftItem.NFTImageLink}
                  nftName={auctionNFT.NftItem.NftName}
                  nftPrice={auctionNFT.NftItem.NFTPrice}
                  nftOwnerDisplayName={auctionNFT.UserData.userName}
                  nftOwnerImg={auctionNFT.UserData.userProfileImage}
                  nftTokenId={auctionNFT.NftItem.ContractId.toString()}
                />
              </React.Fragment>
            );
          })}
        </div>
      </div>
      <div className="live_auction_section">
        <br />
        <br />
        <h2>Today's NFT Picks</h2>
        <br />
        <br />
        <div
          className="live_auction_section_flex"
          style={{ marginTop: "50px" }}
        >
          {todayNFTPicks.map((nftData, index) => {
            return (
              <React.Fragment key={index}>
                <NFTCard
                  nftOwnerAddress={nftData.NftItem.NFTOwner}
                  isNftInMarketplace={true}
                  nftAnimationUrl={nftData.NftItem.NFTMediaLink}
                  nftContractAddress={nftData.NftItem.CollectionAddress}
                  nftImage={nftData.NftItem.NFTImageLink}
                  nftName={nftData.NftItem.NftName}
                  nftPrice={nftData.NftItem.NFTPrice}
                  nftOwnerDisplayName={nftData.UserData.userName}
                  nftOwnerImg={nftData.UserData.userProfileImage}
                  nftTokenId={nftData.NftItem.ContractId.toString()}
                />
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default HomePage;
