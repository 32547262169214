import { ToastContainer } from "react-toastify";
import { HeroDrops } from "./../../components/HeroDrops/index";
import styles from "./Home.module.css";
import "react-toastify/dist/ReactToastify.css";
const MintPage = () => {
  return (
    <div>
      <ToastContainer position="top-right" />
      <div className={styles.heroSection}>
        <HeroDrops />
        <br />
        <br />
      </div>
      <br />
      <br />
      {/* <div className={styles.subHeaderSection} >
                <h4 className={styles.h4Text}  >Active Drops</h4>
                <div className={styles.horizontalDivider} >  </div>
                </div> */}
      <br />
      <br />
      <br />
      {/* <div className={styles.listOfDrops}  > 
                <DropsCard/>
                <DropsCard/>
                <DropsCard/>
                <DropsCard/>
                <DropsCard/>
                 </div> */}
      <br />
      <br />
      {/* <div className={styles.subHeaderSection} >
                <h4 className={styles.h4Text}  >Upcoming Drops</h4>
                <div className={styles.horizontalDivider} >  </div>
                </div> */}
      <br />
      <br />
      <br />
      {/* <div className={styles.listOfDrops}  > 
                <DropsCard/>
                <DropsCard/>
                <DropsCard/>
                <DropsCard/>
                <DropsCard/>
                 </div> */}
    </div>
  );
};

export default MintPage;
