import type {
  CollectionDetailsAndNFTInMarket,
  GenericResponse,
  HomePageFeedRes,
  IGetNtfItemFromDbExplore,
  VagueResponse,
} from "../../types/dto/response";
import type {
  IGetCollection,
  IGetNftForExplore,
} from "../../types/dto/requests";
import { API_URL } from "../../constants/main";
import { Collection } from "../../types/marketItems/main";

const getNftDataForExplorePage = (
  startCursor: number
): Promise<IGetNtfItemFromDbExplore> => {
  return new Promise(async (resolve, reject) => {
    const requestObject: IGetNftForExplore = {
      startCursor: startCursor,
    };

    const fetchApi = await fetch(`${API_URL}/feed/getNftsForExplore`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(requestObject),
    });

    const jsonResponse: IGetNtfItemFromDbExplore = await fetchApi.json();
    resolve(jsonResponse);
  });
};

const fetchHomePageFeedData = (): Promise<HomePageFeedRes> => {
  return new Promise(async (resolve, reject) => {
    const fetchApi = await fetch(`${API_URL}/feed/getHomePageData`);
    const homePaegData: HomePageFeedRes = await fetchApi.json();
    resolve(homePaegData);
  });
};

const fetchAllCollections = (): Promise<
  GenericResponse<Collection[] | null>
> => {
  return new Promise(async (resolve, reject) => {
    try {
      const api = await fetch(`${API_URL}/collection/getAllCollections`);

      const apiRes: GenericResponse<Collection[]> = await api.json();

      resolve(apiRes);
    } catch (error) {
      console.log(error);
      const response: GenericResponse<null> = {
        data: null,
        reason: "An Error Occured",
        success: false,
      };

      resolve(response);
    }
  });
};

const fetchSingleCollectionAndNftInMarket = (
  contractAddress: string
): Promise<GenericResponse<CollectionDetailsAndNFTInMarket | null>> => {
  return new Promise(async (resolve, reject) => {
    try {
      const reqBody: IGetCollection = {
        contractAddress: contractAddress,
      };
      const api = await fetch(`${API_URL}/collection/getCollectionAndNfts`, {
        method: "POST",
        body: JSON.stringify(reqBody),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const apiRes: GenericResponse<CollectionDetailsAndNFTInMarket> =
        await api.json();
      resolve(apiRes);
    } catch (error) {
      console.log(error);

      const response: GenericResponse<null> = {
        success: false,
        data: null,
        reason: "An Error Occured",
      };

      resolve(response);
    }
  });
};

export {
  getNftDataForExplorePage,
  fetchHomePageFeedData,
  fetchAllCollections,
  fetchSingleCollectionAndNftInMarket,
};
