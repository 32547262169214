import { API_URL } from "../../../constants/main";
import { IGetProfileReq } from "../../../types/dto/requests";
import { IGetUserNFTInWallet } from "../../../types/dto/response";

const getNFTsInUserWallet = (
  userAddress: string
): Promise<IGetUserNFTInWallet> => {
  return new Promise(async (resolve, reject) => {
    const getNftsInWalletObject: IGetProfileReq = {
      userAddress: userAddress,
    };
    const getNftsInWalletReq = await fetch(
      `${API_URL}/externalData/getAllNftsInWallet`,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(getNftsInWalletObject),
      }
    );

    const getNftsInWalletRes: IGetUserNFTInWallet =
      await getNftsInWalletReq.json();
    resolve(getNftsInWalletRes);
  });
};

export { getNFTsInUserWallet };
