import { LOCAL_STORAGE_KEY } from "../constants/main"

interface ILocalStorageData{
    userAddress : string,
    loggedIn : boolean
}


const writeToLocalStorage = (userAddress:string)=>{

    const objectToSave:ILocalStorageData = {
        userAddress : userAddress,
        loggedIn : true,
    }

    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(objectToSave))
}

const readLocalStorage = () : ILocalStorageData | null =>{
    const savedObject = localStorage.getItem(LOCAL_STORAGE_KEY)
    if(savedObject != null){
        return JSON.parse(savedObject)
    }else{
        return null
    }
     
}

const clearLocalStorage = ()=>{
    localStorage.removeItem(LOCAL_STORAGE_KEY)
}

export {writeToLocalStorage, readLocalStorage,clearLocalStorage}