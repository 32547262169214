import styles from "./index.module.css";
// import PageBanner from "../../assets/testBanner.webp";
// import TestProfile from "../../assets/profileIMg.jpg";
import { BsGlobe } from "react-icons/bs";
import { AiFillTwitterCircle } from "react-icons/ai";
import { useEffect, useState } from "react";
import { NftItem } from "../../types/nft_types/nft_type";
import { Collection } from "../../types/marketItems/main";
import { fetchSingleCollectionAndNftInMarket } from "../../apiFunctions/feed_api/main";
import { useParams } from "react-router-dom";
import NFTCard from "../../components/NftCard";
import React from "react";
const SingleCollectionPage = () => {
  const { address } = useParams();

  const [collectionDetails, setCollectionDetails] = useState<Collection | null>(
    null
  );

  const [allNftsInCollection, setAllNftsInCollection] = useState<NftItem[]>([]);

  const handleCollectionAndNftDetails = async (contractAddress: string) => {
    const collectionData = await fetchSingleCollectionAndNftInMarket(
      contractAddress
    );

    console.log(collectionData);
    if (collectionData.success && collectionData.data !== null) {
      setCollectionDetails(collectionData.data.collectionData);
      console.log(collectionData.data.collectionData);
      setAllNftsInCollection([...collectionData.data.nftsInMarket]);
    }
  };

  useEffect(() => {
    if (address !== undefined) {
      handleCollectionAndNftDetails(address);
    }
  }, [address]);
  return (
    <>
      {collectionDetails?.coverImage !== undefined &&
      collectionDetails.coverImage !== "" ? (
        <div
          className={styles.collectionBanner}
          style={{
            backgroundImage: `url(${collectionDetails?.coverImage})`,
            backgroundSize: "cover",
          }}
        >
          {/* <img src={collectionDetails?.coverImage} alt="" /> */}
        </div>
      ) : (
        ""
      )}

      <div className={styles.collectionDetails}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "40px",
          }}
        >
          <div className={styles.avatarImage}>
            <img src={collectionDetails?.avatar} alt="" />
          </div>
          <div>
            <h3 className={styles.collectionName}>{collectionDetails?.name}</h3>
            <p className={styles.collectionDescription}>
              {collectionDetails?.description}
            </p>
          </div>
        </div>

        <div className={styles.collectionLinks}>
          <BsGlobe
            size={30}
            color="white"
            onClick={() => {
              window.open(collectionDetails?.websiteLink, "_blank");
            }}
          />
          <AiFillTwitterCircle
            size={30}
            color="white"
            onClick={() => {
              window.open(collectionDetails?.twitterLink, "_blank");
            }}
          />
        </div>
      </div>

      <hr
        style={{
          width: "80%",
          display: "block",
          margin: "auto",
          marginTop: "30px",
        }}
      />

      <h4 className={styles.marketText}>Market : </h4>
      <div className={styles.nftMarketWrap}>
        {allNftsInCollection.map((nftData, index) => {
          return (
            <React.Fragment key={index}>
              <NFTCard
                nftOwnerAddress={nftData.NFTOwner}
                isNftInMarketplace={true}
                nftAnimationUrl={nftData.NFTMediaLink}
                nftContractAddress={nftData.CollectionAddress}
                nftImage={nftData.NFTImageLink}
                nftName={nftData.NftName}
                nftPrice={nftData.NFTPrice}
                nftOwnerDisplayName={""}
                nftOwnerImg={""}
                nftTokenId={nftData.ContractId.toString()}
              />
            </React.Fragment>
          );
        })}
      </div>
    </>
  );
};

export default SingleCollectionPage;
