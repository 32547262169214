import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import ConnectPage from "./pages/ConnectWalletPage";
import AppProvider from "./context/context";
import AuthorPage from "./pages/AuthorPage";
import EdtProfile from "./pages/EditProfilePage";
import NFTItem from "./pages/NftItemPage";
import ExplorePage from "./pages/ExplorePage";
import Header from "./components/Header";
import AuthorPagePublic from "./pages/AuthorPagePublic";
import MintPage from "./pages/MintingPage/Home";
import CollectionsPage from "./pages/CollectionsPage";
import SingleCollectionPage from "./pages/SingleCollectionPage";

function App() {
  return (
    <div>
      <AppProvider>
        <BrowserRouter>
          <Header />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/connect" element={<ConnectPage />} />
            <Route path="/mint" element={<MintPage />} />
            <Route path="/profile" element={<AuthorPage />} />
            <Route path="/profile/:address" element={<AuthorPagePublic />} />
            <Route path="/edit-profile" element={<EdtProfile />} />
            <Route path="/nft-item/:address/:id" element={<NFTItem />} />
            <Route path="collections" element={<CollectionsPage />} />
            <Route
              path="collections/:address"
              element={<SingleCollectionPage />}
            />
            <Route path="/explore" element={<ExplorePage />} />
          </Routes>
        </BrowserRouter>
      </AppProvider>
    </div>
  );
}

export default App;
