import React, { useContext, useState } from "react";
import { NFTHistory, NftItem } from "../../types/nft_types/nft_type";
import "./index.css";

import { IShortUserDetails } from "../../types/users_types/user_types";
import makeBlockie from "ethereum-blockies-base64";
import moment from "moment";
import { AuctionData, NftBid } from "../../types/marketItems/main";
import { useNavigate } from "react-router-dom";
import { AppConfig } from "aws-sdk";
import { AppContext } from "../../context/context";

interface INFTActivityProp {
  isAuctionNFT: boolean;
  nftHistory: NFTHistory[];
  getUserData(userAddress: string): IShortUserDetails | null;
  nftBids: NftBid[] | undefined;
  handleAcceptbid(bidId: string, bidderAddress: string, bidPrice: number): void;
  auctionData: AuctionData | null;
  nftOwner: string;
}

const NFTActivity = ({
  isAuctionNFT,
  nftHistory,
  getUserData,
  nftBids,
  handleAcceptbid,
  auctionData,
  nftOwner,
}: INFTActivityProp) => {
  const [currentIndexView, setCurrentIndexView] = useState(0);

  const nftActivityMenu = isAuctionNFT
    ? ["Bid History", "NFT History"]
    : ["NFT History"];

  const bidWinnerGotten =
    auctionData != null && auctionData.Winner.trim() != "";

  const navigate = useNavigate();

  const { userAddress } = useContext(AppContext);

  return (
    <div>
      <div className="menuSection">
        {nftActivityMenu.map((eachMenu, index) => {
          return (
            <div
              key={index}
              onClick={() => setCurrentIndexView(index)}
              className={currentIndexView === index ? "menuActive" : "menu"}
            >
              <p> {eachMenu}</p>
              <div
                className={currentIndexView === index ? "barActive" : "bar"}
              ></div>
            </div>
          );
        })}
      </div>

      <div className="menuDivider"></div>

      <div className="allNftHistoryWrap">
        {(isAuctionNFT && currentIndexView == 1) ||
        (isAuctionNFT === false && currentIndexView === 0)
          ? nftHistory.map((history, index) => {
              const userData = getUserData(history.UserAddress);
              const momentParser = moment.unix(history.Time);
              return (
                <React.Fragment key={index}>
                  <div className="nftHistory" key={index}>
                    <div className="activityUserDetails">
                      <div
                        className="userImageDiv"
                        onClick={() => {
                          if (history.UserAddress === userAddress) {
                            navigate("/profile");
                          } else {
                            navigate(`/profile/${history.UserAddress}`);
                          }
                        }}
                      >
                        <img
                          src={
                            userData?.userProfileImage.trim() != ""
                              ? userData?.userProfileImage
                              : makeBlockie(userData.userAddress)
                          }
                          alt=""
                          className="userImage"
                        />
                      </div>
                      <div></div>
                      <div className="activityDetails">
                        <p>
                          {" "}
                          <b>
                            {" "}
                            {userData !== null
                              ? userData.userName
                              : history.UserAddress.slice(0, 4)}
                          </b>{" "}
                          {userData !== null
                            ? history.Description.replace(
                                `${userData.userAddress.slice(
                                  0,
                                  6
                                )}...${userData.userAddress.slice(
                                  userAddress.length - 4,
                                  userAddress.length
                                )}`.toLowerCase(),
                                ""
                              )
                            : history.Description}{" "}
                        </p>
                        <p style={{ marginTop: "-10px", fontSize: "13px" }}>
                          {momentParser
                            .utc()
                            .format("dddd, MMMM Do YYYY, h:mm:ss a")}{" "}
                        </p>
                      </div>
                    </div>
                    {history.ValueInvolved > 0 ? (
                      <div className="historyValue">
                        <b>{`${history.ValueInvolved} wDoge`}</b>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <br />
                  <div
                    className="menuDivider"
                    style={{
                      width: "80%",
                      display: "block",
                      margin: "auto",
                      marginTop: "-20px",
                      marginLeft: "12%",
                    }}
                  ></div>
                </React.Fragment>
              );
            })
          : ""}
        {isAuctionNFT && currentIndexView == 0 && nftBids != undefined
          ? nftBids.map((bid, index) => {
              const userData = getUserData(bid.BidderAddress);
              const momentParser = moment.unix(bid.TimeBidPlaced);
              return (
                <React.Fragment key={index}>
                  <div className="nftHistory" key={index}>
                    <div className="activityUserDetails">
                      <div
                        className="userImageDiv"
                        onClick={() => {
                          if (bid.BidderAddress === userAddress) {
                            navigate("/profile");
                          } else {
                            navigate(`/profile/${bid.BidderAddress}`);
                          }
                        }}
                      >
                        <img
                          src={
                            userData?.userProfileImage.trim() != ""
                              ? userData?.userProfileImage
                              : makeBlockie(userData.userAddress)
                          }
                          alt=""
                          className="userImage"
                        />
                      </div>
                      <div></div>
                      <div className="activityDetails">
                        <p>
                          {userData !== null
                            ? userData.userName
                            : bid.BidderAddress.slice(0, 4)}{" "}
                          Placed a Bid{" "}
                        </p>
                        <p style={{ marginTop: "-10px", fontSize: "13px" }}>
                          {momentParser
                            .utc()
                            .format("dddd, MMMM Do YYYY, h:mm:ss a")}{" "}
                        </p>
                      </div>
                    </div>

                    {bidWinnerGotten === false && userAddress === nftOwner ? (
                      <button
                        className="acceptBidBtn"
                        onClick={() =>
                          handleAcceptbid(
                            bid.BidId,
                            bid.BidderAddress,
                            bid.BidPrice
                          )
                        }
                      >
                        Accept Bid
                      </button>
                    ) : (
                      ""
                    )}

                    {bid.BidPrice > 0 ? (
                      <div className="historyValue">
                        <b>{`${bid.BidPrice} wDoge`}</b>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <br />
                  <div
                    className="menuDivider"
                    style={{
                      width: "80%",
                      display: "block",
                      margin: "auto",
                      marginTop: "-20px",
                      marginLeft: "12%",
                    }}
                  ></div>
                </React.Fragment>
              );
            })
          : ""}
      </div>
    </div>
  );
};

export default NFTActivity;
