import {useState, useRef, useContext, useEffect, useCallback} from 'react'
import { AppContext } from '../../context/context'
import './index.css'

import {v4} from 'uuid'
import { uploadImageToS3 } from '../../apiFunctions/s3_upload/main'
import { getUserProfile, updateUserProfile } from '../../apiFunctions/user_api/main'
import {ToastContainer, toast} from 'react-toastify'
import makeBlockie from 'ethereum-blockies-base64'

const EdtProfile = ()=> {

    const [profileImageLink, setProfileImageLink] = useState("")
    const [coverImageLink, setCoverImageLink] =  useState("")
    const [displayName, setDisplayName] = useState("")
    const [emailAddress, setEmailAddress] = useState("")
    const [userBio, setUserBio] = useState("")

    const profileImageRef = useRef<HTMLInputElement>(null)
    const coverImageRef = useRef<HTMLInputElement>(null)

    const {userAddress} = useContext(AppContext)

    


   const handleProfilePictureUpload = async(files:FileList)=>{
       const loadingToast = toast.loading("Uploading Profile Image...")
       console.log(files)
       const file =  files[0]
       const fileName = file.name.split(".")[0] + userAddress + v4() + "." + file.name.split(".")[1]
       console.log(fileName)
       const link =  await uploadImageToS3('profileImage', fileName, file)
       setProfileImageLink(link)
        toast.update(loadingToast, {render : "Uploaded Successfully", isLoading : false, type : 'success', autoClose:1200})

   } 

//    const handleCoverImageUpload = async(files:FileList)=>{
//     console.log(files)
//     const file =  files[0]
//     const fileName = file.name.split(".")[0] + userAddress + v4() + "." + file.name.split(".")[1]
//     console.log(fileName)
//     const link = await uploadImageToS3('coverImage', fileName, file)
//     setCoverImageLink(link)

//    }


   const handleGetUserProfile = useCallback(async()=>{
        const userData =  await getUserProfile(userAddress)
        console.log(userData)
        if(userData.success === true){
            setProfileImageLink(userData.data.UserData.Avatar)
            setCoverImageLink(userData.data.UserData.ProfileCover)
            setDisplayName(userData.data.UserData.UserName)
            setUserBio(userData.data.UserData.BioText)
            setEmailAddress(userData.data.UserData.EmailAddress)
        }
        else{
            alert(userData.reason)
        }
   }, [userAddress])

   useEffect(()=>{
    handleGetUserProfile()
},[userAddress,handleGetUserProfile])


   const handleUpdateUserProfile = async()=>{
       const updateResponse = await updateUserProfile(profileImageLink, coverImageLink, displayName, userBio, userAddress, emailAddress)
       toast("Profile Updated Successfully", {autoClose:1200})
       console.log(updateResponse)
   }

  return (
    <div>
            <br /><br />
            <ToastContainer position='top-right'  />
            <input type="file" accept='image/*' onChange={(event)=>{
                const target  =  event.target as HTMLInputElement
                if(target.files!= null){
                    handleProfilePictureUpload(target.files)
                }
            }} style={{display:'none'}} ref={profileImageRef} name="" id="" />
            {/* <input type="file" accept='image/*'  onChange={(event)=>{
                const target  =  event.target as HTMLInputElement
                if(target.files!= null){
                    handleCoverImageUpload(target.files)
                }
            }}  style={{display:'none'}} ref={coverImageRef} name="" id="" /> */}
            <h1  style={{marginTop:"100px", textAlign:'center', color:'white'}}>Edit Profile </h1>
            <br /><br />
            <div style={{backgroundColor:'black'}}>
                <div className='edit_dash_flex' >
                    <div className='profile_img_edit' >
                        <div className='profile_image_view' >
                        <img src={profileImageLink.trim() !== "" ? profileImageLink : makeBlockie(userAddress) } alt="" />

                        </div>
                        <button  className='button_style_one' onClick={()=>{profileImageRef.current?.click()}} >  Upload New Photo</button>
                        <button  className='button_style_one'>  Delete Photo </button>
                    </div>
                    <div className='edit_data_section' >
                        <h4  className='edit_data_header' >Choose Your Cover Image</h4>
                        <div className='uploadCoverImage_flex' >
                            <div className='upload_cover_image_div' >
                            <button  className='button_style_one'  style={{width:'60%', display:'block', marginLeft:'auto', marginRight:'auto'}} onClick={()=>{coverImageRef.current?.click()}} >  Upload File </button>

                                 </div>
                            <div className='upload_cover_image_div' style={{background:'#343444'}} >
                                {
                                    coverImageLink.trim() !== "" ?<img src={ coverImageLink} alt="" /> : ""

                                }
                                 </div>
                            
                        </div>
                        <div className='inputFieldSectionFlex' >
                            <div className='inputFieldInnerSection' >
                                <h4>Account Info</h4>
                                <br /><br />
                                <div className='inputDiv' >
                                    <p>Display Name</p>
                                    <input type="text" placeholder='' value={displayName} onChange={(event)=>{
                                        setDisplayName(event.target.value)
                                    }}  />
                                </div>
                                <div className='inputDiv' >
                                    <p>Email Address</p>
                                    <input type="text" placeholder=''  value={emailAddress} onChange={(event)=>{
                                        setEmailAddress(event.target.value)
                                    }} />
                                </div>
                                {/* <div className='inputDiv' >
                                    <p>Custom URL</p>
                                    <input type="text" placeholder=''  />
                                </div> */}
                                <div className='inputDiv' >
                                    <p>Bio</p>
                                    <textarea  placeholder='' value={userBio} onChange={(event)=>{
                                        setUserBio(event.target.value)
                                    }}  />
                                </div>
                            </div>
                        </div>
                        <br /><br />
                        <button style={{fontSize:'18px'}} className='button_style_one' onClick={()=>handleUpdateUserProfile()} >  Update Profile </button>

                    </div>
                </div>
            </div>
    </div>
  )
}

export default EdtProfile