import { useContext, useEffect, useState } from "react";
import classes from "./index.module.css";
import { AppContext } from "../../context/context";
import { IoCopyOutline } from "react-icons/io5";
import { AiOutlineDisconnect } from "react-icons/ai";
import { FiEdit3 } from "react-icons/fi";
import NFTCard from "../../components/NftCard";
import { Link, useNavigate } from "react-router-dom";
import { getUserProfile, logoutUser } from "../../apiFunctions/user_api/main";
import makeBlockie from "ethereum-blockies-base64";
import { getNFTsInUserWallet } from "../../apiFunctions/user_api/external_user_data/main";
import { clearLocalStorage, readLocalStorage } from "../../utils/localStorage";
import {
  IGetProfileRes,
  IGetUserNFTInWallet,
  MoralisNftResultParsed,
} from "../../types/dto/response";
import { NftItem, NFTMetaData } from "../../types/nft_types/nft_type";
import { toast, ToastContainer } from "react-toastify";

const AuthorPage = () => {
  let isMounted = true;

  const [userProfileImage, setProfileImage] = useState("");
  // const [userAddress ,setUserAddress] = useState("")
  const [displayName, setDisplayName] = useState("");
  const [userBio, setUserBio] = useState("");

  const [nftsInWallet, setNftsInWallet] = useState<NFTMetaData[]>([]);

  const [nftsListedInMarketPlace, setNftsListedInMarketPlace] = useState<
    NftItem[]
  >([]);
  const [nftsNotListedInMarketPlace, setNotNftsListedInMarketPlace] = useState<
    NftItem[]
  >([]);

  const [currentNftTypeIndex, setCurrentNftTypeIndex] = useState(0);

  const { userAddress, setUserAddress, setConnected } = useContext(AppContext);

  const router = useNavigate();

  const handleGetUserProfile = async () => {
    const profileLoadingId = toast.loading("Loading Profile");
    let addressToUse = userAddress;
    if (userAddress === "" || userAddress === undefined) {
      const userData = readLocalStorage();
      if (userData !== null) {
        addressToUse = userData.userAddress;
      }
    }
    const arrayOfDataToFetch: [
      Promise<IGetProfileRes>,
      Promise<IGetUserNFTInWallet>
    ] = [getUserProfile(addressToUse), getNFTsInUserWallet(userAddress)];
    const dataFetched = await Promise.all(arrayOfDataToFetch);
    const userData = dataFetched[0];
    if (userData.success === true && userData.data !== undefined && isMounted) {
      setUserBio(userData.data.UserData.BioText);
      setProfileImage(userData.data.UserData.Avatar);
      setDisplayName(userData.data.UserData.UserName);
      toast.update(profileLoadingId, {
        render: "Profile Loaded successfully",
        type: "success",
        isLoading: false,
        autoClose: 1500,
      });
    }

    const nftInWalletRes = dataFetched[1];

    console.log(userData);

    const nftsInDataBase = userData.data.NftItems;

    console.log(nftInWalletRes);

    const allNftCollectionResInWallet = nftInWalletRes.data.map(
      (eachResData) => {
        return eachResData;
      }
    );

    const allNftInWallet = allNftCollectionResInWallet.flat();

    const allNftInWalletWithParsedImages = allNftInWallet.map((eachNft) => {
      const currentImageString = eachNft.image;
      const parsedImageString = currentImageString.replace(
        "ipfs://",
        "https://ipfs.io/ipfs/"
      );

      eachNft.image = parsedImageString;

      return eachNft;
    });

    const arrayOfNftsInWalletNotInMarketplace =
      allNftInWalletWithParsedImages.filter((eachNft) => {
        let arrayOfNfts = nftsInDataBase.some(
          (nftDBValue) =>
            nftDBValue.CollectionAddress.toLowerCase().trim() ===
              eachNft.contractAddress.trim().toLowerCase() &&
            nftDBValue.ContractId === eachNft.tokenId
        );

        if (arrayOfNfts === false) {
          return eachNft;
        } else {
        }
      });

    // Could Combine these two  declerative functions into 1
    const arrayOfNftsListedForSale = nftsInDataBase.filter((eachNFT) => {
      return eachNFT.ListedForSale === true;
    });

    const arrayOfNftsNotListedForSale = nftsInDataBase.filter((eachNft) => {
      return eachNft.ListedForSale === false;
    });

    console.log(
      arrayOfNftsNotListedForSale,
      arrayOfNftsListedForSale,
      arrayOfNftsInWalletNotInMarketplace
    );

    if (isMounted) {
      setNftsInWallet(arrayOfNftsInWalletNotInMarketplace);
      setNftsListedInMarketPlace(arrayOfNftsListedForSale);
      setNotNftsListedInMarketPlace(arrayOfNftsNotListedForSale);
      toast.update(profileLoadingId, {
        render: "Profile Loaded successfully",
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };

  useEffect(() => {
    const getAddress = readLocalStorage();
    setUserAddress(getAddress?.userAddress);
    handleGetUserProfile();
    return () => {
      isMounted = false;
    };
  }, []);

  const handleWalletDisconnect = async () => {
    const logoutDetails = await logoutUser(userAddress);
    console.log(logoutDetails);
    if (logoutDetails.success) {
      router({
        pathname: "/connect",
      });
      clearLocalStorage();
      setUserAddress("");
      setConnected(false);
    } else {
      console.log(logoutDetails.reason);
    }
  };

  const handleCopyAddress = (text: string) => {
    navigator.clipboard.writeText(text);
    toast("Address copied to clipboard");
  };

  const nftType = [
    "On Sale",
    "In Marketplace (not for sale)",
    "In Wallet (not in marketplace )",
  ];
  return (
    <div>
      <>
        <ToastContainer position="top-right" />
        <br />
        <h1 style={{ marginTop: "100px", textAlign: "center", color: "white" }}>
          My Profile{" "}
        </h1>
        <br />
        <br />
        <div style={{ backgroundColor: "black", width: "100%" }}>
          <div className={classes.userDetailsBlock}>
            <div className={classes.userProfileDetails}>
              <div className={classes.userImageBlock}>
                {userAddress !== "" && userAddress !== undefined ? (
                  <img
                    src={
                      userProfileImage.trim() !== ""
                        ? userProfileImage
                        : makeBlockie(userAddress)
                    }
                    alt=""
                  />
                ) : (
                  ""
                )}
              </div>
              <div className={classes.userNameSection}>
                <h3>{displayName}</h3>
                <p>{userBio}</p>
                <div style={{ flexWrap: "wrap", display: "flex", gap: "20px" }}>
                  <div className={classes.walletAddressDiv}>
                    <p>
                      {`${userAddress.slice(0, 6)}...${userAddress.slice(
                        userAddress.length - 5,
                        userAddress.length - 1
                      )}`}
                    </p>
                    <IoCopyOutline
                      size={20}
                      color="white"
                      onClick={() => handleCopyAddress(userAddress)}
                    />
                  </div>
                  <Link to="/edit-profile" style={{ textDecoration: "none" }}>
                    <div className={classes.editProfileDiv}>
                      <p>Edit Profile</p>
                      <FiEdit3 size={20} color="white" />
                    </div>
                  </Link>
                  <div
                    className={classes.editProfileDiv}
                    onClick={() => {
                      handleWalletDisconnect();
                    }}
                  >
                    <p>Disconnect Wallet</p>
                    <AiOutlineDisconnect size={20} color="white" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div className={classes.nftTypeSection}>
            {nftType.map((eachNftType, index) => {
              return (
                <div
                  onClick={() => setCurrentNftTypeIndex(index)}
                  key={index}
                  className={
                    currentNftTypeIndex === index
                      ? classes.nftOwnershipTypeActive
                      : classes.nftOwnershipType
                  }
                >
                  {eachNftType}
                </div>
              );
            })}
          </div>
          <br />
          <br />

          {currentNftTypeIndex === 2 ? (
            <div className={classes.listedNftSection}>
              {nftsInWallet.map((eachNFT, index) => {
                return (
                  <NFTCard
                    key={index}
                    nftOwnerDisplayName={displayName}
                    nftOwnerImg={userProfileImage}
                    nftImage={eachNFT.image}
                    isNftInMarketplace={false}
                    nftOwnerAddress={userAddress}
                    nftName={eachNFT.name}
                    nftContractAddress={eachNFT.contractAddress}
                    nftTokenId={eachNFT.tokenId.toString()}
                  />
                );
              })}
            </div>
          ) : currentNftTypeIndex === 0 ? (
            <div className={classes.listedNftSection}>
              {nftsListedInMarketPlace.map((eachNft, index) => {
                return (
                  <NFTCard
                    key={index}
                    nftOwnerAddress={eachNft.NFTOwner}
                    nftAnimationUrl={eachNft.NFTMediaLink}
                    isNftInMarketplace={true}
                    nftContractAddress={eachNft.CollectionAddress}
                    nftImage={eachNft.NFTImageLink}
                    nftName={eachNft.NftName}
                    nftOwnerDisplayName={displayName}
                    nftOwnerImg={userProfileImage}
                    nftPrice={eachNft.NFTPrice}
                    nftTokenId={eachNft.ContractId.toString()}
                  />
                );
              })}
            </div>
          ) : currentNftTypeIndex === 1 ? (
            <div className={classes.listedNftSection}>
              {nftsNotListedInMarketPlace.map((eachNft, index) => {
                return (
                  <NFTCard
                    key={index}
                    nftOwnerAddress={eachNft.NFTOwner}
                    nftAnimationUrl={eachNft.NFTMediaLink}
                    isNftInMarketplace={true}
                    nftContractAddress={eachNft.CollectionAddress}
                    nftImage={eachNft.NFTImageLink}
                    nftName={eachNft.NftName}
                    nftOwnerDisplayName={displayName}
                    nftOwnerImg={userProfileImage}
                    nftPrice={eachNft.NFTPrice}
                    nftTokenId={eachNft.ContractId.toString()}
                  />
                );
              })}
            </div>
          ) : (
            ""
          )}

          <br />
          <br />
        </div>
      </>
    </div>
  );
};

export default AuthorPage;
