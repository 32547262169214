import NFTABI from "../ABI/nftAbi.json";

import NFTMarketPlaceAbi from "../ABI/marketPlace.json";
import DOGE_NFT_ABI from "../ABI/DOGE_NFT.json";
import { Contract, ethers } from "ethers";

declare let window: any;

export const nftMarketContractAddress =
  "0x5093149F403680C524cDF1a17f8aC2773727cEEe";

export const nftContractAddress = "0x949994430d5602dF45F887D6adae5E8E041946Ec";

export const dogeContractAddress = "0x6B351dC4439A9aC313f6f4D76c51f2D3717F3101";

let nftContract: Contract;

let nftMarketPlaceContract: Contract;

// let dogeNFTContractAddress: Contract;

if (window.ethereum) {
  const provider = new ethers.providers.Web3Provider(window.ethereum, "any");

  const signer = provider.getSigner();
  nftContract = new ethers.Contract(nftContractAddress, NFTABI, signer);

  nftMarketPlaceContract = new ethers.Contract(
    nftMarketContractAddress,
    NFTMarketPlaceAbi,
    signer
  );
}

const getNFTContract = (contractAddress: string): ethers.Contract => {
  const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
  const signer = provider.getSigner();
  return new ethers.Contract(contractAddress, NFTABI, signer);
};

const getDogeNFTContract = (contractAddress: string): ethers.Contract => {
  const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
  const signer = provider.getSigner();
  return new ethers.Contract(contractAddress, DOGE_NFT_ABI, signer);
};

export {
  nftMarketPlaceContract,
  getNFTContract,
  nftContract,
  getDogeNFTContract,
};
