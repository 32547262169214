import { CoinGeckoCoinRes } from "./interface/main.interface"



export const getTokenPrice = (tokenId:string):Promise<number>=>{
    return new Promise(async(resolve,reject)=>{
        const tokenDataReq =  await fetch(`https://api.coingecko.com/api/v3/coins/${tokenId}`)
        const tokenData:CoinGeckoCoinRes = await tokenDataReq.json()
        const tokenPrice =  tokenData.market_data.current_price.usd
        console.log(tokenPrice)
        resolve(tokenPrice)
    })
}