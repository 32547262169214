import { useEffect, useState} from 'react'
import  classes from './index.module.css'

import {IoCopyOutline} from 'react-icons/io5'
import NFTCard from '../../components/NftCard'
import { useParams } from 'react-router-dom'
import { getUserProfile } from '../../apiFunctions/user_api/main'
import makeBlockie from 'ethereum-blockies-base64'
import { IGetProfileRes,  } from '../../types/dto/response'
import { NftItem, } from '../../types/nft_types/nft_type'

import { toast, ToastContainer } from 'react-toastify'
const AuthorPage = ()=>{

    let isMounted =  true;

    const [userProfileImage, setProfileImage] =  useState("")
    // const [coverImage, setCoverImage] = useState("")
    // const [userAddress ,setUserAddress] = useState("")
    const[displayName, setDisplayName] =  useState("")
    const [userBio, setUserBio] =  useState("")

    // const [authorAddress, setAuthorAddress] = useState("")


    const [nftsListedInMarketPlace, setNftsListedInMarketPlace] = useState<NftItem[]>([])



    // const router =  useNavigate()

    const {address} = useParams()

    const userAddress = address !== undefined ? address : ""
    
    console.log(address)

    const handleGetUserProfile = async()=>{

        const arrayOfDataToFetch:[Promise<IGetProfileRes>] = [getUserProfile(userAddress)] 
        const dataFetched =  await Promise.all(arrayOfDataToFetch)
        const userData = dataFetched[0]
        console.log(userData)
        if(userData.success === true && userData.data != undefined && isMounted){
            setUserBio(userData.data.UserData.BioText)
            setProfileImage(userData.data.UserData.Avatar)
            // setCoverImage(userData.data.UserData.ProfileCover)
            setDisplayName(userData.data.UserData.UserName)
        }
        if(isMounted){
            setNftsListedInMarketPlace(userData.data.NftItems)
        }
    }

    useEffect(()=>{
    // setAuthorAddress(userAddress)
     handleGetUserProfile()   
     return ()=>{
         isMounted =  false
     }
    },[])

    const handleCopyAddress = (text:string)=>{
        navigator.clipboard.writeText(text)
        toast("Address copied to clipboard")
    }

    return(
        <div>
            <ToastContainer position='top-right' />
            <br />
            <h1  style={{marginTop:"100px", textAlign:'center', color:'white'}}>My Profile </h1>
            <br /><br />
            <div style={{backgroundColor:'black'}}>

            <div className={classes.userDetailsBlock} >
                <div className={classes.userProfileDetails} >
                    <div className={classes.userImageBlock} >

                        <img src={userProfileImage.trim() !== "" ? userProfileImage : makeBlockie(userAddress) } alt="" />
                    </div>
                    <div className={classes.userNameSection} >
                        <h3>{displayName}</h3>
                        <p>{userBio}</p>
                        <div style={{flexWrap:'wrap', display:'flex', gap:'20px'}} >
                        <div className={classes.walletAddressDiv}>
                            <p>
                               {`${userAddress.slice(0,6)}...${userAddress.slice(userAddress.length -5, userAddress.length-1)}`} 
                            </p>
                            <IoCopyOutline size={20} color='white' onClick={()=>handleCopyAddress(userAddress)} />
                        </div>
                        </div>
                    </div>
                    
                </div>
            </div >
            <br /><br />
            <br /><br />

            {
                 <div className={classes.listedNftSection} >
                {
                    nftsListedInMarketPlace.map((eachNft, index)=>{
                        return <NFTCard  key={index} nftOwnerAddress={eachNft.NFTOwner} nftAnimationUrl={eachNft.NFTMediaLink} isNftInMarketplace={true} nftContractAddress={eachNft.CollectionAddress} nftImage={eachNft.NFTImageLink} nftName={eachNft.NftName} nftOwnerDisplayName={displayName}  nftOwnerImg={userProfileImage} nftPrice={eachNft.NFTPrice} nftTokenId={eachNft.ContractId.toString()} />
                    })
                }
            </div> 
            }
                
                <br /><br />
        </div>
        </div>
    )
}

export default AuthorPage