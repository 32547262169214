import "./index.css";

import MetaMaskLogo from "../../assets/MetamaskLogo.png";
import CoinBaseLogo from "../../assets/coinBaseWallet.png";

import { ethers } from "ethers";

import {
  confirmUserAccountCreated,
  createUserAccount,
  loginUser,
} from "../../apiFunctions/user_api/main";
import { useContext } from "react";
import { AppContext } from "../../context/context";

import { useNavigate } from "react-router-dom";
import { writeToLocalStorage } from "../../utils/localStorage";
import { toast, ToastContainer } from "react-toastify";

interface IWalletOptionProps {
  walletImage: string;
  walletName: string;
  connectToWallet: Function;
}

interface IWalletData {
  walletImage: string;
  walletName: string;
}

const supportedWallet: IWalletData[] = [
  {
    walletName: "Meta Mask",
    walletImage: MetaMaskLogo,
  },
  {
    walletName: "Coinbase Wallet",
    walletImage: CoinBaseLogo,
  },
];

const ConnectPage = () => {
  const navigator = useNavigate();

  const { setUserAddress, setConnected } = useContext(AppContext);

  const connectToWallet = async () => {
    const windowObject: any = window;
    const provider = new ethers.providers.Web3Provider(
      windowObject.ethereum,
      "any"
    );
    const signer = provider.getSigner();
    const chainId = await signer.getChainId();
    console.log(signer);
    const accountsInWallet = await provider.send("eth_requestAccounts", []);
    console.log(accountsInWallet);
    console.log(chainId);
    if (chainId !== 4) {
      if (accountsInWallet > 0) {
        const loggingInToast = toast.loading("Logging You in ...");
        const userAddress = accountsInWallet[0];
        console.log(userAddress);
        const userData = await confirmUserAccountCreated(userAddress);
        if (
          userData.success === true &&
          userData.data.UserData != (undefined || null) &&
          userData.reason !== "User Not Found"
        ) {
          console.log("Account Created");
          try {
            const loginUserData = await loginUser(userAddress);
            if (loginUserData.success) {
              setUserAddress(userAddress);
              writeToLocalStorage(userAddress);
              setConnected(true);
              navigator("/");
              toast.update(loggingInToast, {
                render: "Logged in successfully",
                autoClose: 1500,
                type: "success",
                isLoading: false,
              });
            } else {
              console.log(loginUserData.reason);
              toast.update(loggingInToast, {
                render: loginUserData.reason,
                autoClose: 1500,
                type: "error",
                isLoading: false,
              });
            }
          } catch (error) {
            toast.update(loggingInToast, {
              render: "An error occured",
              autoClose: 1500,
              type: "error",
              isLoading: false,
            });
          }
        } else {
          const createdAccountData = await createUserAccount(userAddress);
          console.log(createdAccountData);
          setUserAddress(userAddress);
          writeToLocalStorage(userAddress);
          toast.update(loggingInToast, {
            render: "Logged in successfully",
            autoClose: 1500,
            type: "success",
            isLoading: false,
          });
          setConnected(true);
          navigator("/");
        }
      }
    } else {
      toast("You need to connect to Rinkeby Testnet to connect to this DAPP", {
        type: "error",
        autoClose: 1500,
      });
    }
  };

  return (
    <div>
      <ToastContainer position="top-right" />
      <br />
      <br />

      <h1 style={{ marginTop: "100px", textAlign: "center", color: "white" }}>
        Connect Wallet{" "}
      </h1>
      <br />
      <br />

      <div className="connect_options_wrap">
        {supportedWallet.map((eachWallet, index) => {
          return (
            <WalletOption
              connectToWallet={connectToWallet}
              key={index}
              walletImage={eachWallet.walletImage}
              walletName={eachWallet.walletName}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ConnectPage;

const WalletOption = ({
  walletImage,
  walletName,
  connectToWallet,
}: IWalletOptionProps) => {
  return (
    <div className="wallet_option">
      <img src={walletImage} alt="" />
      <p>{walletName}</p>
      <button onClick={() => connectToWallet()}>Connect Wallet</button>
    </div>
  );
};
