import styles from "./index.module.css";

type CountDownType = {
  total: number;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  completed: boolean;
};

const CountDownRender = ({ days, hours, minutes, seconds }: CountDownType) => {
  return (
    <p className={styles.countDownText} style={{ color: "black" }}>
      {" "}
      {days}d {hours}h {minutes}m {seconds}s{" "}
    </p>
  );
};

export default CountDownRender;
