import { useEffect, useState, useContext, useCallback } from "react";
import "./index.css";

// import Elf from '../../assets/waifu-elf.webp'

import { RiShoppingBag2Fill } from "react-icons/ri";

import { BsViewList } from "react-icons/bs";
import { BiImport } from "react-icons/bi";

import { useParams } from "react-router-dom";
import {
  getNftItemFromDBWithContractAddressAndId,
  getNftItemFromMoralis,
  getUsersInvolvedInNft,
  importNftToMarketPlace,
  listNftInMarketPlace,
  unListNftItem,
  UpdateNftAfterPurchase,
} from "../../apiFunctions/nft_api/main";
// import { IGetNftItemByContractAndId } from '../../types/dto/requests'
import { IGetNtfItemFromDBRes } from "../../types/dto/response";
import { NFTAttribute, NFTHistory } from "../../types/nft_types/nft_type";
import makeBlockie from "ethereum-blockies-base64";
import { AppContext } from "../../context/context";
import NFTListingForm from "../../components/NFTListingForm";
import { IShortUserDetails } from "../../types/users_types/user_types";
import NFTActivity from "../../components/NFTActivity";
import CountDown from "react-countdown";
import { AuctionData, Collection, NftBid } from "../../types/marketItems/main";
import NFTBiddingForm from "../../components/NFTBiddingForm";
import {
  acceptBid,
  placeBidOnAuction,
} from "../../apiFunctions/auction_api/main";
import {
  approveMarketPlaceToTransferNFT,
  getMarketPlaceApprovalStatus,
} from "../../smartContractFunctions/nftFunctions";

import {
  getNFTMarketItem,
  listNFTinMarketPlaceContract,
  purchaseNFTInMarketPlace,
  unListFromMarketPlace,
} from "../../smartContractFunctions/marketPlaceFunctions";
import Loader from "../../components/Loader/Loader";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getCollection } from "../../apiFunctions/collection_api/main";

const NFTItem = () => {
  const [nftContractAddress, setNftContractAddress] = useState("");
  const [nftTokenId, setNftTokenId] = useState(0);
  const [nftIdDb, setNftIdDb] = useState("");
  const [nftName, setNftName] = useState("");
  const [isNftInMarketplace, setIsNftInMarketPlace] = useState(false);
  const [nftDescription, setNftDescription] = useState("");
  const [nftImage, setNftImage] = useState("");
  const [nftAnimationUrl, setNFTAnimationUrl] = useState("");
  // const [isNftMetaDataValid, setIsNftMetaDataValid] = useState(true)
  const [isNftListedInMarketplace, setIsNftListedInMarketplace] =
    useState(false);
  const [nftOwnerAddress, setNftOwnerAddress] = useState("");
  const [nftCreatorAddress, setNftCreatorAddress] = useState("");
  const [listNFTFormActive, setListNftFormActive] = useState(false);
  const [bidOnNftModeActive, setBidOnNftModeActive] = useState(false);
  const [nftHistory, setNftHistory] = useState<NFTHistory[]>([]);
  const [nftTraits, setNftTraits] = useState<NFTAttribute[]>([]);
  const [nftContractType, setNftContractType] = useState("");
  const [nftAuctionDeadline, setNftAuctionDeadline] = useState(0);
  const [isAuctionNft, setIsAuctionNft] = useState(false);
  const [nftPrice, setNftPrice] = useState<number | null>(null);
  const [usersInvolvedInNft, setUsersInvolvedINft] = useState<
    IShortUserDetails[]
  >([]);
  const [auctionData, setAuctionData] = useState<AuctionData | null>(null);
  const [highestBid, setHighestBid] = useState<NftBid>();
  const [isFormActionTLoading, setIsFormActionLoading] =
    useState<boolean>(false);
  const [actionLoading, setActionLoading] = useState<boolean>(false);
  const [collectionDetails, setCollectionDetails] = useState<Collection | null>(
    null
  );

  const { address, id } = useParams();

  const { userAddress, connected, ethereumPrice } = useContext(AppContext);

  type CountDownType = {
    total: number;
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
    completed: boolean;
  };

  const CountDownRender = ({
    total,
    days,
    hours,
    minutes,
    seconds,
    completed,
  }: CountDownType) => {
    return (
      <div className="countDownBlocksWrap">
        <div className="countDownBlock">
          <p>{days}d</p>
        </div>{" "}
        :
        <div className="countDownBlock">
          <p>{hours}h</p>
        </div>{" "}
        :
        <div className="countDownBlock">
          <p>{minutes}m</p>
        </div>{" "}
        :
        <div className="countDownBlock">
          <p>{seconds}s</p>
        </div>
      </div>
    );
  };

  const getHighestBid = (bidsInAuction: NftBid[]): NftBid => {
    let highestBid = bidsInAuction[0];
    for (let i = 0; i < bidsInAuction.length; i++) {
      if (highestBid.BidPrice < bidsInAuction[i].BidPrice) {
        highestBid = bidsInAuction[i];
      }
    }

    return highestBid;
  };

  const getNftDataFromContract = async (address: string, id: string) => {
    const nftDataFromMoralis = await getNftItemFromMoralis(
      address,
      parseInt(id)
    );
    if (nftDataFromMoralis.success === true) {
      setIsNftInMarketPlace(false);
      setNftName(nftDataFromMoralis.data.name);
      setNftDescription(nftDataFromMoralis.data.description);
      setNftImage(
        nftDataFromMoralis.data.image.replace(
          "ipfs://",
          "https://ipfs.io/ipfs/"
        )
      );
      setNFTAnimationUrl(nftDataFromMoralis.data.animation_url);
      setNftOwnerAddress(nftDataFromMoralis.data.ownerAddress);
      setNftContractAddress(nftDataFromMoralis.data.contractAddress);
      setNftTraits(nftDataFromMoralis.data.attributes);

      setNftContractType("ERC-721");
      setNftTokenId(nftDataFromMoralis.data.tokenId);
      // setIsNftMetaDataValid(false)
    }
  };
  const getNFTCollectionData = useCallback(async () => {
    if (address !== undefined && address !== null) {
      const collectionData = await getCollection(address);
      console.log(collectionData);
      console.log(collectionData.data.creator);
      if (collectionData.success) {
        setCollectionDetails(collectionData.data);
        setNftCreatorAddress(collectionData.data.creator);
      }
    }
  }, [address]);

  const getNftData = useCallback(async () => {
    const loadNft = toast.loading("Loading NFT Data....", { autoClose: 500 });
    if (address !== undefined && id !== undefined) {
      const arrayOfPromises: [Promise<IGetNtfItemFromDBRes>] = [
        getNftItemFromDBWithContractAddressAndId(address, parseInt(id)),
      ];
      const resolvedPromises = await Promise.all(arrayOfPromises);
      console.log(resolvedPromises);
      const nftFromDb = resolvedPromises[0];
      if (nftFromDb.data !== undefined) {
        setIsNftInMarketPlace(true);
        setNftName(nftFromDb.data.NftItem.NftName);
        setNftDescription(nftFromDb.data.NftItem.NFTDescription);
        setNftImage(nftFromDb.data.NftItem.NFTImageLink);
        setNFTAnimationUrl(nftFromDb.data.NftItem.NFTMediaLink);
        setNftIdDb(nftFromDb.data.NftItem.NFTID);
        setIsNftListedInMarketplace(nftFromDb.data.NftItem.ListedForSale);
        setNftOwnerAddress(nftFromDb.data.NftItem.NFTOwner);
        setNftContractAddress(nftFromDb.data.NftItem.CollectionAddress);
        setNftContractType(nftFromDb.data.NftItem.NFTType);
        setNftTokenId(nftFromDb.data.NftItem.ContractId);
        setNftPrice(nftFromDb.data.NftItem.NFTPrice);
        setNftOwnerAddress(nftFromDb.data.NftItem.NFTOwner);
        setNftHistory(nftFromDb.data.NftItem.NFTHistory);
        if (nftFromDb.data.NftItem.IsAuctionNFT === true) {
          setAuctionData(nftFromDb.data.AuctionData);
          setNftAuctionDeadline(nftFromDb.data.AuctionData.Deadline);
          setHighestBid(getHighestBid(nftFromDb.data.AuctionData.Bids));
        }
        setIsAuctionNft(nftFromDb.data.NftItem.IsAuctionNFT);

        const userInvolvedRes = await getUsersInvolvedInNft(
          nftFromDb.data.NftItem.NFTID
        );
        if (userInvolvedRes.success === true) {
          setUsersInvolvedINft(userInvolvedRes.data);
        }
      }
    }

    if (address !== undefined && id !== undefined) {
      const nftDataFromMoralis = await getNftItemFromMoralis(
        address,
        parseInt(id)
      );
      if (nftDataFromMoralis.success === true) {
        // setIsNftInMarketPlace(false);
        setNftName(nftDataFromMoralis.data.name);
        setNftDescription(nftDataFromMoralis.data.description);
        setNftImage(
          nftDataFromMoralis.data.image.replace(
            "ipfs://",
            "https://ipfs.io/ipfs/"
          )
        );
        setNFTAnimationUrl(nftDataFromMoralis.data.animation_url);
        setNftOwnerAddress(nftDataFromMoralis.data.ownerAddress);
        setNftContractAddress(nftDataFromMoralis.data.contractAddress);
        setNftTraits(nftDataFromMoralis.data.attributes);

        setNftContractType("ERC-721");
        setNftTokenId(nftDataFromMoralis.data.tokenId);
        // setIsNftMetaDataValid(false)
      }
    }

    toast.update(loadNft, {
      isLoading: false,
      type: "success",
      render: "NFT Data Loaded Successfully",
      autoClose: 500,
    });
  }, [address, id]);

  const handleImportNftToMarketPlace = async () => {
    setActionLoading(true);

    try {
      const response = await importNftToMarketPlace(
        nftName,
        nftTokenId,
        0,
        nftOwnerAddress,
        "",
        userAddress,
        [],
        nftContractType,
        "image",
        nftImage,
        false,
        nftContractAddress,
        nftDescription,
        0,
        nftAnimationUrl
      );
      if (response.success === true) {
        getNftData();
        toast("NFT Imported Successfully");
      }
      setActionLoading(false);
      console.log(response);
      toast(response.reason);
    } catch (e) {
      console.log(e);
      setActionLoading(false);
      toast("An Error Occured");
    }
  };

  const handleListNftInMarketPlace = async (
    auctionDeadline: number,
    isAuctionNft: boolean,
    newNftPrice: number,
    useContract: boolean
  ) => {
    if (newNftPrice <= 0) {
      toast("Cannot List NFT at this price", {
        type: "error",
        autoClose: 3000,
      });
    } else {
      console.log(auctionDeadline, isAuctionNft, newNftPrice);
      try {
        if (useContract) {
          const isMarketPlaceApproved = await getMarketPlaceApprovalStatus(
            nftContractAddress,
            nftTokenId
          );
          console.log(isMarketPlaceApproved);
          if (isMarketPlaceApproved === false) {
            const nftApproveLoad = toast.loading("Approving NFT... ", {
              closeButton: true,
              closeOnClick: true,
            });
            const contractApproval = await approveMarketPlaceToTransferNFT(
              nftContractAddress,
              nftTokenId
            );
            console.log(contractApproval);
            if (contractApproval === true) {
              toast.update(nftApproveLoad, {
                isLoading: false,
                type: "success",
                render: "NFT Approved",
                autoClose: 500,
              });
            }
          }

          const nftListLoad = toast.loading("Listing NFT in Marketplace... ", {
            closeButton: true,
            closeOnClick: true,
          });
          const hasNftBeenListedInContract = await listNFTinMarketPlaceContract(
            nftContractAddress,
            nftTokenId,
            newNftPrice,
            5,
            "0xBb54229bE98aE4dd54DAfBFaD52c3B5f799d31b3"
          );
          console.log(hasNftBeenListedInContract);
          if (hasNftBeenListedInContract) {
            const response = await listNftInMarketPlace(
              auctionDeadline,
              isAuctionNft,
              newNftPrice,
              nftIdDb,
              userAddress,
              0
            );
            console.log(response);
            if (response.success) {
              setListNftFormActive(false);
              toast.update(nftListLoad, {
                isLoading: false,
                type: "success",
                render: "NFT Listed Successfully",
                autoClose: 1200,
              });
              window.scrollBy({
                behavior: "smooth",
                top: -500,
              });
              getNftData();
            } else {
              toast.update(nftListLoad, {
                isLoading: false,
                autoClose: 500,
                render: response.reason,
                type: "error",
              });
            }
          }
        } else {
          const nftListLoad = toast.loading("Listing NFT in Marketplace... ", {
            closeButton: true,
            closeOnClick: true,
          });
          const response = await listNftInMarketPlace(
            auctionDeadline,
            isAuctionNft,
            newNftPrice,
            nftIdDb,
            userAddress,
            0
          );
          console.log(response);
          if (response.success) {
            setListNftFormActive(false);
            window.scrollBy({
              behavior: "smooth",
              top: -500,
            });
          } else {
          }
          toast.update(nftListLoad, {
            isLoading: false,
            type: "success",
            render: "NFT Listed Successfully",
            autoClose: 1200,
          });
          getNftData();
        }
      } catch (error) {
        console.log(error);
        toast.dismiss();
        setIsFormActionLoading(false);
        toast("An Error Occured", { autoClose: 2000, type: "error" });
      }
    }
  };

  const handleDelistNft = async () => {
    setActionLoading(true);
    const loadInit = toast.loading("Unlisting NFT...", {
      closeButton: true,
      closeOnClick: true,
    });
    try {
      const nftItem = await getNFTMarketItem(nftContractAddress, nftTokenId);
      console.log(nftItem);
      if (nftItem !== undefined) {
        const delistNFTRes = await unListFromMarketPlace(nftItem.itemId);
        console.log(delistNFTRes);
        if (delistNFTRes) {
          const response = await unListNftItem(
            nftIdDb,
            userAddress,
            isAuctionNft
          );
          console.log(response);
          setActionLoading(false);
          toast.update(loadInit, {
            isLoading: false,
            render: "NFT Unlisted Successfully",
            type: "success",
            autoClose: 1200,
          });
          getNftData();
        } else {
          toast.update(loadInit, {
            isLoading: false,
            render: "Something Went Wrong",
            type: "success",
            autoClose: 1200,
          });
        }
        setActionLoading(false);
      } else {
        await unListNftItem(nftIdDb, userAddress, isAuctionNft);
        setActionLoading(false);
        toast.update(loadInit, {
          isLoading: false,
          render: "Unlisted NFT From MarketPlace",
          type: "success",
          autoClose: 1200,
        });
      }
    } catch (error) {
      console.log(error);
      setActionLoading(false);
    }
  };

  useEffect(() => {
    getNftData();
    getNFTCollectionData();
  }, [getNftData, getNFTCollectionData]);

  const getUserDataForView = (
    userAddress: string
  ): IShortUserDetails | null => {
    let userData = usersInvolvedInNft.filter((eachUserData) => {
      return (
        eachUserData.userAddress.toLowerCase().trim() ===
        userAddress.toLowerCase().trim()
      );
    });

    if (userData.length !== 0) {
      return userData[0];
    } else {
      return null;
    }
  };

  const HandleBidNft = async (newNftPrice: number) => {
    const loadBidNFT = toast.loading("Placing Bid....");

    try {
      if (auctionData?.ID !== undefined) {
        const response = await placeBidOnAuction(
          newNftPrice,
          userAddress,
          auctionData?.ID
        );
        if (response.success) {
          getNftData();
          toast.update(loadBidNFT, {
            render: "Bid Placed",
            isLoading: false,
            autoClose: 1000,
            type: "success",
          });
          setBidOnNftModeActive(false);
        }
      }
    } catch (error) {
      console.log(error);
      toast.update(loadBidNFT, {
        render: "Something Occured",
        isLoading: false,
        autoClose: 1000,
        type: "error",
      });
    }
  };

  const handleAcceptBid = async (
    bidId: string,
    nftId: string,
    auctionId: string,
    bidPrice: number,
    bidderAddress: string
  ) => {
    const loadAcceptBidId = toast.loading("Accepting Bid....");
    try {
      const isMarketPlaceApproved = await getMarketPlaceApprovalStatus(
        nftContractAddress,
        nftTokenId
      );
      console.log(isMarketPlaceApproved);
      if (isMarketPlaceApproved === false) {
        const contractApproval = await approveMarketPlaceToTransferNFT(
          nftContractAddress,
          nftTokenId
        );
        console.log(contractApproval);
      }
      const hasNftBeenListedInContract = await listNFTinMarketPlaceContract(
        nftContractAddress,
        nftTokenId,
        bidPrice,
        5,
        "0xBb54229bE98aE4dd54DAfBFaD52c3B5f799d31b3"
      );
      console.log(hasNftBeenListedInContract);

      const response = await acceptBid(bidId, auctionId, bidderAddress, nftId);
      console.log(response);
      if (response.success) {
        toast.update(loadAcceptBidId, {
          render: "Bid Accepted",
          type: "success",
          isLoading: false,
          autoClose: 1000,
        });
        getNftData();
      } else {
        toast.update(loadAcceptBidId, {
          render: response.reason,
          type: "error",
          isLoading: false,
        });
      }
      setActionLoading(false);
    } catch (e) {
      console.log(e);
      setActionLoading(false);
      toast.update(loadAcceptBidId, {
        render: "Something went wrong",
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };

  const handleUpdateAfterNFTPurchase = async (nftPrice: number) => {
    setActionLoading(true);
    const nftPurchaseLoadId = toast.loading("Purchasing NFT....");
    try {
      const nftItemInContract = await getNFTMarketItem(
        nftContractAddress,
        nftTokenId
      );
      console.log(nftItemInContract);
      const nftPurchasedInContract = await purchaseNFTInMarketPlace(
        nftContractAddress,
        nftItemInContract.itemId,
        nftPrice
      );
      if (nftPurchasedInContract) {
        const response = await UpdateNftAfterPurchase(
          nftIdDb,
          nftOwnerAddress,
          userAddress,
          nftPrice,
          isAuctionNft
        );
        console.log(response);
        toast.update(nftPurchaseLoadId, {
          type: "success",
          isLoading: false,
          render: "NFT Purchased Successfully...",
          autoClose: 1200,
        });
        if (response.success) {
          getNftData();
        } else {
          toast(response.reason, { type: "error" });
        }
      } else {
        console.log("An error occured");
        toast.update(nftPurchaseLoadId, {
          type: "error",
          render: "An error occured",
          isLoading: false,
          autoClose: 1000,
        });
      }
      setActionLoading(false);
    } catch (error) {
      console.log(error);
      setActionLoading(false);
      toast.update(nftPurchaseLoadId, {
        type: "error",
        render: "An error occured",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <ToastContainer position="top-right" closeOnClick={true} />
      <br />
      <br />
      <h1 style={{ marginTop: "100px", textAlign: "center", color: "white" }}>
        {nftName}
      </h1>
      <br />
      <br />

      <div className="nftDetailsMainDiv">
        <div className="nftDetailsInner">
          <div className="nftImageSection">
            {nftImage !== undefined && nftImage.trim() !== "" ? (
              <img src={nftImage} alt="" />
            ) : (
              <video
                src={nftAnimationUrl}
                autoPlay={false}
                controls={true}
              ></video>
            )}

            <div
              className="traitSection"
              style={{
                marginTop: "50px",
              }}
            >
              <div className="traitHeaderSection">
                <p>Traits</p>
                <p>Value</p>
              </div>
              <hr
                style={{
                  width: "98%",
                  backgroundColor: "white",
                  marginTop: "-10px",
                }}
              />
              {nftTraits.map((attribute, index) => {
                return (
                  <div key={index} className="traitHeaderSection">
                    <p>{attribute.trait_type}</p>
                    <p>{attribute.value}</p>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="nftTextDetails">
            <h2>{nftName}</h2>
            <br />
            <div className="nftCreatorSection">
              {isNftInMarketplace === true ? (
                <div className="nftCreatorDiv">
                  <div className="nftCreatorImg">
                    {(nftOwnerAddress.trim() !== "" &&
                      getUserDataForView(nftOwnerAddress) === null) ||
                    getUserDataForView(
                      nftOwnerAddress
                    )?.userProfileImage.trim() === "" ? (
                      <img src={makeBlockie(nftOwnerAddress)} alt="" />
                    ) : (
                      <img
                        src={
                          getUserDataForView(nftOwnerAddress)?.userProfileImage
                        }
                        alt=""
                      />
                    )}
                  </div>
                  <div className="nftCreatorText">
                    <p>Owned By</p>
                    <h3>{getUserDataForView(nftOwnerAddress)?.userName}</h3>
                  </div>
                </div>
              ) : (
                <div className="nftCreatorDiv">
                  <div className="nftCreatorImg">
                    {nftOwnerAddress.trim() !== "" &&
                    getUserDataForView(nftOwnerAddress) === null ? (
                      <img src={makeBlockie(nftOwnerAddress)} alt="" />
                    ) : (
                      <img
                        src={
                          getUserDataForView(nftOwnerAddress)?.userProfileImage
                        }
                        alt=""
                      />
                    )}
                  </div>
                  <div className="nftCreatorText">
                    <p>Owned By</p>
                    <h3>
                      {nftOwnerAddress.slice(0, 6)}...
                      {nftOwnerAddress.slice(
                        nftOwnerAddress.length - 4,
                        nftOwnerAddress.length
                      )}
                    </h3>
                  </div>
                </div>
              )}
              {nftCreatorAddress !== "" && isNftInMarketplace ? (
                <div className="nftCreatorDiv">
                  <div className="nftCreatorImg">
                    {" "}
                    {getUserDataForView(nftCreatorAddress) === null &&
                    nftCreatorAddress !== "" ? (
                      <img src={makeBlockie(nftCreatorAddress)} alt="" />
                    ) : (
                      <img
                        src={
                          getUserDataForView(nftCreatorAddress)
                            ?.userProfileImage
                        }
                        alt=""
                      />
                    )}
                  </div>
                  <div className="nftCreatorText">
                    <p>Created By</p>
                    <h3>{getUserDataForView(nftCreatorAddress)?.userName}</h3>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="nftDescription">
              <p>{nftDescription}</p>
            </div>

            <div className="priceInfoSection">
              {isNftInMarketplace === true && nftPrice !== (0 && null) ? (
                <div className="nftPriceSection">
                  <div className="nftPriceDiv">
                    <p>Current Price</p>
                    <h3>
                      {nftPrice} wDoge = $
                      {(Number(nftPrice) * ethereumPrice).toLocaleString(
                        "en-US"
                      )}{" "}
                    </h3>
                  </div>
                </div>
              ) : (
                ""
              )}

              {isAuctionNft ? (
                <div className="nftBidSection">
                  {auctionData?.Bids !== undefined &&
                  auctionData.Bids.length > 0 ? (
                    <div className="currentBidDiv">
                      <p>Highest Bid</p>
                      <div>
                        <b>{highestBid?.BidPrice} wDoge</b>
                        <b>
                          {" "}
                          = ~ $
                          {(
                            Number(highestBid?.BidPrice) * ethereumPrice
                          ).toLocaleString("en-US")}
                        </b>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {isAuctionNft &&
                  nftOwnerAddress === userAddress &&
                  auctionData?.Winner.trim() === "" &&
                  highestBid !== undefined ? (
                    <button
                      className="acceptBidBtn"
                      onClick={() => {
                        if (
                          highestBid !== undefined &&
                          auctionData !== undefined
                        ) {
                          handleAcceptBid(
                            highestBid.BidId,
                            nftIdDb,
                            auctionData.ID,
                            highestBid.BidPrice,
                            highestBid.BidderAddress
                          );
                        }
                      }}
                    >
                      Accept Highest Bid
                    </button>
                  ) : auctionData?.Winner.trim() !== "" &&
                    nftOwnerAddress === userAddress ? (
                    <button className="acceptBidBtn" onClick={() => {}}>
                      Revoke Bid Winner
                    </button>
                  ) : (
                    ""
                  )}
                  <div className="countDownSection">
                    {auctionData?.Winner === "" ? (
                      <CountDown
                        date={nftAuctionDeadline}
                        autoStart={true}
                        renderer={CountDownRender}
                      />
                    ) : (
                      <p style={{ color: "white", textAlign: "center" }}>
                        Auction has Ended, {auctionData?.Winner.slice(0, 4)}...
                        {auctionData?.Winner.slice(
                          auctionData.Winner.length - 4,
                          auctionData.Winner.length
                        )}{" "}
                        is the Winner{" "}
                      </p>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <br />
            {isNftListedInMarketplace === false ? (
              <p style={{ color: "white" }}>
                This Item is Currently not listed for sale
              </p>
            ) : (
              ""
            )}

            <br />
            {isNftInMarketplace === true &&
            nftOwnerAddress.trim().toLowerCase() !==
              userAddress.trim().toLowerCase() &&
            isAuctionNft === false &&
            connected &&
            isNftListedInMarketplace ? (
              <button
                className="button_style_one"
                onClick={() => {
                  if (nftPrice != null) {
                    handleUpdateAfterNFTPurchase(nftPrice);
                  }
                }}
              >
                <RiShoppingBag2Fill size={30} color="#343444" />
                <p>Buy Nft</p>
              </button>
            ) : (
              ""
            )}
            {isNftInMarketplace === true &&
            nftOwnerAddress !== userAddress &&
            isAuctionNft === true &&
            auctionData?.Winner !== userAddress ? (
              <button
                className="button_style_one"
                onClick={() => {
                  setBidOnNftModeActive(true);
                  window.scroll({
                    behavior: "smooth",
                    top: 700,
                  });
                }}
              >
                <RiShoppingBag2Fill size={30} color="#343444" />
                <p>Place a Bid</p>
              </button>
            ) : isNftInMarketplace === true &&
              nftOwnerAddress !== userAddress &&
              isAuctionNft === true &&
              auctionData?.Winner !== "" &&
              auctionData?.Winner === userAddress ? (
              <button
                className="button_style_one"
                onClick={() => {
                  if (nftPrice !== null) handleUpdateAfterNFTPurchase(nftPrice);
                  else alert("Price is Invalid");
                }}
              >
                <RiShoppingBag2Fill size={30} color="#343444" />
                <p>You won the Auction !!! Purchase NFT</p>
              </button>
            ) : (
              ""
            )}

            {isNftInMarketplace === true &&
            nftOwnerAddress.trim().toLowerCase() ===
              userAddress.trim().toLowerCase() &&
            isNftListedInMarketplace ? (
              <button
                className="button_style_one"
                onClick={() => handleDelistNft()}
              >
                <BsViewList size={30} color="#343444" />
                <p>Unlist from Marketplace</p>
              </button>
            ) : (
              ""
            )}
            {isNftInMarketplace === true &&
            nftOwnerAddress.trim().toLowerCase() ===
              userAddress.trim().toLowerCase() &&
            isNftListedInMarketplace === false ? (
              <button
                onClick={() => {
                  setListNftFormActive(true);
                  window.scrollBy({
                    behavior: "smooth",
                    top: 700,
                  });
                }}
                className="button_style_one"
              >
                <BsViewList size={30} color="#343444" />
                <p>List in Marketplace</p>
              </button>
            ) : (
              ""
            )}
            {isNftInMarketplace === false &&
            nftOwnerAddress.trim().toLowerCase() ===
              userAddress.trim().toLowerCase() ? (
              <button
                onClick={() => handleImportNftToMarketPlace()}
                className="button_style_one"
              >
                <BiImport size={30} color="#343444" />
                <p>Import into Marketplace</p>
              </button>
            ) : (
              ""
            )}
            {actionLoading ? <Loader /> : ""}
            <br />
            <br />
            <NFTActivity
              isAuctionNFT={isAuctionNft}
              nftHistory={nftHistory}
              getUserData={(userAddress: string) =>
                getUserDataForView(userAddress)
              }
              nftBids={auctionData?.Bids}
              auctionData={auctionData != null ? auctionData : null}
              handleAcceptbid={(bidId, bidderAddress, bidPrice) => {
                if (auctionData !== undefined && auctionData !== null) {
                  console.log("From Activity");
                  handleAcceptBid(
                    bidId,
                    nftIdDb,
                    auctionData.ID,
                    bidPrice,
                    bidderAddress
                  );
                }
              }}
              nftOwner={nftOwnerAddress}
            />
          </div>
        </div>

        {listNFTFormActive ? (
          <NFTListingForm
            handleListNft={(
              auctionDeadline: number,
              isAuctionNft: boolean,
              newNftPrice: number
            ) =>
              handleListNftInMarketPlace(
                auctionDeadline,
                isAuctionNft,
                newNftPrice,
                !isAuctionNft
              )
            }
            showLoader={isFormActionTLoading}
          />
        ) : (
          ""
        )}

        {bidOnNftModeActive ? (
          <NFTBiddingForm
            handleBidNft={(bidPrice: number) => HandleBidNft(bidPrice)}
            showLoader={isFormActionTLoading}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default NFTItem;
