// import TestBanner from "../../assets/testBanner.webp";
// import TestLogo from "../../assets/profileIMg.jpg";
// import { TiTicket } from "react-icons/ti";
import { Navigate, Router, useNavigate } from "react-router-dom";
import styles from "./index.module.css";

interface IExploreCollectionType {
  name: string;
  avatarImage: string;
  coverImage: string;
  collectionAddress: string;
}

const ExploreCard = ({
  avatarImage,
  coverImage,
  name,
  collectionAddress,
}: IExploreCollectionType) => {
  const router = useNavigate();

  return (
    <div className={styles.parentCard}>
      <div className={styles.imageDiv}>
        {coverImage !== "" && coverImage !== undefined ? (
          <img
            src={coverImage}
            alt="testBanner"
            className=" rounded-lg rounded-br-none rounded-bl-none "
          />
        ) : (
          ""
        )}
      </div>

      <div className={styles.avaterImgDiv}>
        <img src={avatarImage} alt="" />
      </div>

      <p className={styles.collectionName}>{name}</p>

      {/* <h3 className=" font-bold  px-3 -mt-10 w-3/4 font-SpaceG ">
        Test Project has a Fun GiveAway{" "}
      </h3> */}

      <button
        className={styles.viewMoreButton}
        onClick={() => {
          router(`/collections/${collectionAddress}`);
        }}
      >
        View More
      </button>

      {/* <div className=" flex-row gap-3  flex items-center mt-12 px-2  ">
        <TiTicket color="rgb(96 165 250)" size={25} />
        <p>1000</p>
      </div> */}
    </div>
  );
};

export default ExploreCard;
