import { useEffect, useState } from "react";
import { fetchAllCollections } from "../../apiFunctions/feed_api/main";
import ExploreCard from "../../components/ExploreCard";
import { Collection } from "../../types/marketItems/main";
import styles from "./index.module.css";

const CollectionsPage = () => {
  const [allCollections, setAllCollections] = useState<Collection[]>([]);

  const handleGetAllCollections = async () => {
    const collections = await fetchAllCollections();

    if (collections.success && collections.data !== null) {
      setAllCollections([...collections.data]);
    }
  };

  useEffect(() => {
    handleGetAllCollections();
  }, []);

  return (
    <>
      <div
        style={{
          marginTop: "100px",
        }}
      >
        <h2 className={styles.collectionText}>Collections</h2>
        <div className={styles.listOfCollections}>
          {allCollections.map((eachCollection, index) => {
            return (
              <ExploreCard
                avatarImage={eachCollection.avatar}
                coverImage={eachCollection.coverImage}
                name={eachCollection.name}
                collectionAddress={eachCollection.contractAddress}
                key={index}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default CollectionsPage;
