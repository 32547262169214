import { API_URL } from "../../constants/main"
import { IGetCollection } from "../../types/dto/requests"
import { GenericResponse } from "../../types/dto/response"
import { Collection } from "../../types/marketItems/main"

const getCollection = (address:string):Promise<GenericResponse<Collection>>=>{
    return new Promise(async(resolve,reject)=>{
        const requestBody:IGetCollection = {
            contractAddress : address
        } 
        const fetchApiRes = await fetch(`${API_URL}/collection/getCollection`, {
            method : 'POST',
            headers : {
                'Content-Type' : 'application/json'
            },
            credentials : 'include',
            body : JSON.stringify(requestBody)
        })
        const response:GenericResponse<Collection> = await fetchApiRes.json()
        resolve(response)
    })
}

export {getCollection}