import { useState } from "react"
import './index.css'

import {GoChevronDown} from 'react-icons/go'


interface IFilterTabProps{
    filterSelected : any,
    handleSetFilter:Function
    filterList : string[]
}

const FilterTab = ({filterSelected, handleSetFilter, filterList}: IFilterTabProps) =>{

    const [showListingTypeFilter, setShowListingTypeFilter] = useState(false) 


    return (
        <div   onMouseEnter={()=>{
            setShowListingTypeFilter(true)
        }} 
        onMouseLeave={()=>{
            setShowListingTypeFilter(false)
        }}
        onClick={()=>{
            setShowListingTypeFilter(true)

        }}>
        <div  className={showListingTypeFilter ? "selectorSectionActive" :"selectorSection"}
        >
            <p>{filterSelected}</p>
            <GoChevronDown color='white' size={15} />
        </div>
           <div className={showListingTypeFilter ? "optionSectionActive" : "optionSection"} >
            {
                filterList.map((filter, index)=>{
                    return <div 
                    onClick={()=>{
                        handleSetFilter(filter)
                        setShowListingTypeFilter(false)
                    }}
                    key={index}
                    className={filter ===  filterSelected ? "optionActive" : "option"} >
                    <p>{filter}</p>
                </div>
                })
            }
            
        </div> 
        
        </div>
    )
}



export default FilterTab