import { useContext, useEffect, useState } from 'react'
import { getNftDataForExplorePage } from '../../apiFunctions/feed_api/main'
import FilterTab from '../../components/FilterTab'
import NFTCard from '../../components/NftCard'
import { AppContext, ExplorePageCacheTrack } from '../../context/context'
import type { NftItemAndAuctionAndUser  } from '../../types/dto/response'
import styles from './index.module.css'
const ExplorePage = ()=>{

    const [startSearchCursor, setSearchCursor] = useState(0)

    const [allNftData, setAllNftData]  = useState<NftItemAndAuctionAndUser[]>([])
    const [allNftDataView, setAllNftDataView]  = useState<NftItemAndAuctionAndUser[]>([])

    const [isLoadingNftData, setIsLoadingNftData] =  useState(true)

    const listingTypeFilters = ["Auction Listing", "Fixed Price Listing"]

    const pricingFilterType = ["Highest price", "Lowest Price"]

    const [listingTypeSelected, setListingTypeSelected ] =  useState("")

    const [showLoadMore, setshowLoadMore] = useState(true)

    const [pricingFilterSelected, setPricingFilterSelected] = useState("")


    const {explorePageCacheTrack, setExplorePageCacheTrack} =  useContext(AppContext)

    const handleGetNftData = async(startCusor:number, firstCall:boolean)=>{
        const nftDataRes =  await getNftDataForExplorePage(startCusor)
        console.log(startCusor)
        if (nftDataRes.success ===  true){
            if(firstCall === false){
                setAllNftData([...allNftData,...nftDataRes.data])
                handleFilter([...allNftData,...nftDataRes.data])
                if(nftDataRes.data.length === 0 ){
                    setshowLoadMore(false)
                }
            }
            else{
                setAllNftData(nftDataRes.data)
                setAllNftDataView(nftDataRes.data)
                const explorePageData:ExplorePageCacheTrack = {
                    allNftData : nftDataRes.data,
                    allNFTDataView : nftDataRes.data,
                    positionInPage : document.documentElement.scrollTop,
                    searchCursor : startSearchCursor + 1
                } 
                setExplorePageCacheTrack(explorePageData)
            }
            console.log(nftDataRes)
            const newSearchCursor  = startSearchCursor + 1
            setSearchCursor(newSearchCursor)
        }
        setIsLoadingNftData(false)
    }

    useEffect(()=>{
        console.log(explorePageCacheTrack)
        console.log(allNftData, allNftDataView)
        if (explorePageCacheTrack === null){
            handleGetNftData(startSearchCursor, true)
        }
        else{
            setAllNftData([...explorePageCacheTrack.allNftData])
            setAllNftDataView([...explorePageCacheTrack.allNFTDataView])
            setIsLoadingNftData(false)
            setSearchCursor(explorePageCacheTrack.searchCursor)
            window.scrollBy({
                top : -explorePageCacheTrack.positionInPage,
                behavior : "smooth"
            })
        }
        // window.addEventListener('scroll', handleScroll)
        // return ()=>{
        //     window.removeEventListener('scroll', handleScroll)
        // }
    },[])
    
    useEffect(()=>{
        console.log("filter", listingTypeSelected, pricingFilterSelected)
        if(listingTypeSelected !== "" || pricingFilterSelected !== "" ){
            handleFilter(allNftData)
        }
    },[listingTypeSelected, pricingFilterSelected])


    const handleFilter = (currentNFTData:NftItemAndAuctionAndUser[])=>{
        let nftData : NftItemAndAuctionAndUser[] = currentNFTData
        if(listingTypeSelected === "Auction Listing"){
            const listingRes = currentNFTData.filter(eachNFT=>{
                return eachNFT.AuctionData.ID.trim() !== ""
            })
            nftData = listingRes
        }
        else if(listingTypeSelected === "Fixed Price Listing") {  
            const listingRes =  currentNFTData.filter(eachNft=>{
                return eachNft.AuctionData.ID.trim() === ""
            })
            nftData = listingRes
        }

        if(pricingFilterSelected === 'Highest price'){
            nftData.sort((a, b)=> b.NftItem.NFTPrice - a.NftItem.NFTPrice )
        }
        else if (pricingFilterSelected === "Lowest Price"){
            nftData.sort((a, b)=> a.NftItem.NFTPrice - b.NftItem.NFTPrice )

        }
        

        console.log(startSearchCursor)

        const explorePageData:ExplorePageCacheTrack = {
            allNftData : currentNFTData,
            allNFTDataView : nftData,
            positionInPage : document.documentElement.offsetHeight,
            searchCursor : startSearchCursor + 1
        }

        setExplorePageCacheTrack(explorePageData)

        setAllNftDataView([...nftData])
    }


    return (
        <div>
            <br /><br />
        <h1  style={{marginTop:"100px", textAlign:'center', color:'white'}}>Explore NFTs</h1>
            <br />
            <br />        


      
            <div className={styles.nft_filter_seciton}  > 
            <div className={styles.left_filter_section} >
                <FilterTab filterList={listingTypeFilters} filterSelected={listingTypeSelected} handleSetFilter={(filterSelected:string)=>{setListingTypeSelected(filterSelected)
            }
                
            }  />
                <FilterTab filterList={pricingFilterType} filterSelected={pricingFilterSelected} handleSetFilter={(filterSelected:string)=>{setPricingFilterSelected(filterSelected)
            }
            } />
                <button className={styles.clear_filterBtn}
               onClick={()=> {setAllNftDataView(allNftData) 
                setListingTypeSelected("")
                setPricingFilterSelected("")
               }
            } 
                >  
                    Clear Filters
                </button>
                
                
            </div>
            </div>

            <div className={styles.nfts_section_flex} style={{marginTop:'50px'}} >
            {
                isLoadingNftData === false ? <>
                {
                   allNftDataView.map((nftData, index)=>{
                    const nftInfo = nftData.NftItem
                    // const  auctionInfo =  nftData.AuctionData
                    const  userData  = nftData.UserData
                       return <div key={index}> 
                       <NFTCard  nftOwnerAddress={nftInfo.NFTOwner} isNftInMarketplace={true} key={index} nftAnimationUrl={nftInfo.NFTMediaLink} nftContractAddress={nftInfo.CollectionAddress} nftImage={nftInfo.NFTImageLink} nftName={nftInfo.NftName} nftOwnerDisplayName={userData.userName} nftOwnerImg={userData.userProfileImage} nftPrice={nftInfo.NFTPrice} nftTokenId={nftInfo.ContractId.toString()}  />
                       </div>
                   })
                }
                </> : ""
            }
                
                </div>
                <br /><br /><br />
                {
                    showLoadMore ? <button style={{display:'block', margin:'auto'}} onClick={()=>{
                        handleGetNftData(startSearchCursor, false)
                    }}  className={styles.button} >Load More</button> : ""
                }
                
                <br /><br />

        </div>
    )
}

export default ExplorePage