import { API_URL } from "../../constants/main"
import { IAcceptNFTBidRequest, IBidNft } from "../../types/dto/requests"
import { IAcceptNFTBidRes, VagueResponse } from "../../types/dto/response"

const placeBidOnAuction = (nftBidPrice:number, userAddress:string, auctionId:string):Promise<VagueResponse>=>{

    return new Promise(async(resolve,reject)=>{
        const requestObject:IBidNft = {
            auctionId : auctionId,
            bidderAddress : userAddress,
            biddingPrice : nftBidPrice
        }

        console.log(requestObject)

        const fetchApi =  await fetch(`${API_URL}/auction/bid`, {
            method : 'POST',
            headers :{
                'Content-Type' : 'application/json'
            },
            credentials : 'include',
            body : JSON.stringify(requestObject)
        })

        const responseData:VagueResponse = await fetchApi.json()
        resolve(responseData)
    })

}

const acceptBid = (bidId:string, auctionId:string, bidWinnerAddress:string, nftId:string): Promise<IAcceptNFTBidRes> =>{

    return new Promise(async(resolve,reject)=>{
        
        const requestObject : IAcceptNFTBidRequest = {
             auctionId : auctionId,
            bidId : bidId,
            bidWinnerAddress : bidWinnerAddress,
            nftId : nftId
        }

        const fetchApi =  await fetch(`${API_URL}/auction/acceptBid`, {
            method : 'POST',
            headers : {
                'Content-Type' : 'application/json'
            },
            body : JSON.stringify(requestObject),
            credentials : 'include'
        })

        const responseData:IAcceptNFTBidRes = await fetchApi.json()
        resolve(responseData)

    })
}

export {placeBidOnAuction, acceptBid}