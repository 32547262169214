import { ethers } from "ethers";
import { nftMarketPlaceContract } from "../configs/contract.config";
import { MarketItem } from "../types/contractTypes/nftMarketPlace";

const getListingPrice = (): Promise<string> => {
  return new Promise(async (resolve, reject) => {
    let listingPrice = await nftMarketPlaceContract.getListingPrice();
    listingPrice = listingPrice.toString();
    resolve(listingPrice);
  });
};

const listNFTinMarketPlaceContract = (
  nftContractAddress: string,
  tokenId: number,
  priceOfNFT: number,
  royalties: number,
  nftCreator: string
) => {
  return new Promise(async (resolve, reject) => {
    console.log(priceOfNFT);
    const listingPrice = await getListingPrice();
    const priceOfNFTEthers = ethers.utils.parseUnits(
      priceOfNFT.toString(),
      "ether"
    );
    console.log(
      priceOfNFTEthers,
      royalties,
      nftContractAddress,
      tokenId,
      nftCreator
    );
    try {
      const listNFTCall = await nftMarketPlaceContract.createMarketItem(
        nftContractAddress,
        tokenId.toString(),
        priceOfNFTEthers,
        royalties.toString(),
        nftCreator,
        { value: listingPrice }
      );
      const nftListedRes = await listNFTCall.wait();
      console.log(nftListedRes);
      resolve(true);
    } catch (e) {
      console.log(e);
      reject(false);
    }
  });
};

const getAllMarketItems = (): Promise<MarketItem[]> => {
  return new Promise(async (resolve, reject) => {
    try {
      const nftItems: MarketItem[] =
        await nftMarketPlaceContract.fetchMarketItems();
      resolve(nftItems);
    } catch (e) {
      console.log(e);
      reject([]);
    }
  });
};

const getNFTMarketItem = (
  nftContractAddress: string,
  nftTokenID: number
): Promise<MarketItem> => {
  let nftItem: MarketItem;
  return new Promise(async (resolve, reject) => {
    const allMarketItems = await getAllMarketItems();
    const listOfMarkeItemOnFilter = allMarketItems.filter((eachMarketItem) => {
      const currrentNFTTokenId = eachMarketItem.tokenId.toString();
      console.log(currrentNFTTokenId, nftTokenID.toString());
      console.log(nftContractAddress, eachMarketItem.nftContract);
      return (
        eachMarketItem.nftContract.toLowerCase() ===
          nftContractAddress.toLowerCase() &&
        currrentNFTTokenId === nftTokenID.toString()
      );
    });
    console.log(allMarketItems, listOfMarkeItemOnFilter);
    if (listOfMarkeItemOnFilter.length > 0) {
      resolve(listOfMarkeItemOnFilter[0]);
    } else {
      resolve(nftItem);
    }
  });
};

const unListFromMarketPlace = (marketId: number): Promise<boolean> => {
  return new Promise(async (resolve, reject) => {
    try {
      const unListNFTReq =
        await nftMarketPlaceContract.removeNFTFromMarketPlace(
          marketId.toString()
        );
      await unListNFTReq.wait();
      resolve(true);
    } catch (e) {
      console.log(e);
      reject(false);
    }
  });
};

const purchaseNFTInMarketPlace = (
  contractAddress: string,
  itemId: number,
  valueOfNft: number
) => {
  return new Promise(async (resolve, reject) => {
    const priceOfNftEthers = ethers.utils.parseUnits(
      valueOfNft.toString(),
      "ether"
    );
    try {
      const purchaseNFTReq = await nftMarketPlaceContract.createMarketSale(
        contractAddress,
        itemId,
        { value: priceOfNftEthers }
      );
      await purchaseNFTReq.wait();
      resolve(true);
    } catch (e) {
      console.log(e);
      reject(false);
    }
  });
};

export {
  listNFTinMarketPlaceContract,
  getNFTMarketItem,
  unListFromMarketPlace,
  purchaseNFTInMarketPlace,
};
