// import {} from 'react'
import "./index.css";
import Logo from "../../assets/logo2.png";
import { FaGoogleWallet } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useCallback, useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/context";
import { readLocalStorage } from "../../utils/localStorage";
import { BiMenuAltLeft } from "react-icons/bi";
import { MdOutlineCancel } from "react-icons/md";
import {
  confirmUserAccountCreated,
  getUserAuthStatus,
} from "../../apiFunctions/user_api/main";
import { getTokenPrice } from "../../apiFunctions/coinGecko/main";
import { ethers } from "ethers";
import { toast } from "react-toastify";
const Header = () => {
  const {
    connected,
    userAddress,
    setUserAddress,
    setConnected,
    setEthereumPrice,
  } = useContext(AppContext);

  const navigator = useNavigate();

  const handleUserAuthCheck = useCallback(async () => {
    const localStorageData = readLocalStorage();
    if (localStorageData != null) {
      // const userLoginStatus = await getUserAuthStatus();
      // console.log(userLoginStatus);
      // if (userLoginStatus.success === true) {
      setUserAddress(localStorageData.userAddress);
      setConnected(true);
      // } else {
      //   }
      // } else {
    }
  }, [navigator, setConnected, setUserAddress]);

  const handleGetEthPrice = useCallback(async () => {
    const ethPrice = await getTokenPrice("dogecoin");
    setEthereumPrice(ethPrice);
  }, [setEthereumPrice]);

  useEffect(() => {
    handleUserAuthCheck();
    handleGetEthPrice();
  }, [handleUserAuthCheck]);

  const [isNavOpen, setIsNavOpen] = useState(false);

  const connectToWallet = async () => {
    const windowObject: any = window;
    const provider = new ethers.providers.Web3Provider(
      windowObject.ethereum,
      "any"
    );
    const signer = provider.getSigner();
    const chainId = await signer.getChainId();
    if (chainId !== 2000) {
      toast.error("You need to be on the DogeChain Main-net");
      return;
    }
    console.log(signer);
    const accountsInWallet = await provider.send("eth_requestAccounts", []);
    console.log(accountsInWallet);
    console.log(chainId);
    // if (chainId === 4) {
    if (accountsInWallet > 0) {
      const userAddress = accountsInWallet[0];
      console.log(userAddress);
      setUserAddress(userAddress);
      setConnected(true);
    }
    // } else {
    //   toast("You need to connect to Rinkeby Testnet to connect to this DAPP", {
    //     type: "error",
    //     autoClose: 1500,
    //   });
    // }
  };

  return (
    <div className="mainHeader">
      <Link to="/" style={{ textDecoration: "none", color: "white" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textDecoration: "none",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <h2>DogeSea</h2>
            {/* <p style={{paddingTop:10}}>by</p>
                <img src={Logo} alt="" /> */}
          </div>
          {/* <div>
            <p style={{marginTop:'-20px', marginLeft:'20px'}} >by Waifu House DAO</p>
            </div> */}
        </div>
      </Link>

      <div
        className={isNavOpen === false ? "menu_section" : "menu_sectionActive"}
      >
        <button
          className="button_with_close"
          onClick={() => {
            setIsNavOpen(false);
          }}
        >
          <p>Close</p>
          <MdOutlineCancel
            size={30}
            color="white"
            className="cancelNavButton"
          />
        </button>
        <Link
          to={"/"}
          onClick={() => {
            if (isNavOpen) {
              setIsNavOpen(false);
            }
          }}
          style={{ textDecoration: "none", color: "white" }}
        >
          <p>Home</p>
        </Link>

        {/* <Link
          to={"/explore"}
          style={{ textDecoration: "none", color: "white" }}
          onClick={() => {
            if (isNavOpen) {
              setIsNavOpen(false);
            }
          }}
        >
          <p>Explore NFTs</p>
        </Link> */}
        <Link
          to={"/collections"}
          style={{ textDecoration: "none", color: "white" }}
          onClick={() => {
            if (isNavOpen) {
              setIsNavOpen(false);
            }
          }}
        >
          <p>Collections </p>
        </Link>

        <Link
          to={"/mint"}
          style={{ textDecoration: "none", color: "white" }}
          onClick={() => {
            if (isNavOpen) {
              setIsNavOpen(false);
            }
          }}
        >
          <p>Drops</p>
        </Link>
        {/* <p>Community</p> */}
      </div>
      <div className="action_section">
        {/* <BiSearch color="white"  size={30} /> */}
        {connected === false ? (
          // <Link
          //   to={"/connect"}
          //   style={{ textDecoration: "none", color: "white" }}
          // >
          <button
            className="button_with_wallet"
            onClick={() => {
              navigator("/connect");
            }}
          >
            <FaGoogleWallet color="white" />
            <p>Connect Wallet</p>
          </button>
        ) : (
          // </Link>
          // <Link
          //   to={"/profile"}
          //   style={{ textDecoration: "none", color: "white" }}
          // >
          <button
            className="button_with_wallet"
            onClick={() => {
              navigator("/profile");
            }}
          >
            <FaGoogleWallet color="white" />
            <p>
              {userAddress.slice(0, 4)}...
              {userAddress.slice(userAddress.length - 4, userAddress.length)}
            </p>
          </button>
          // </Link>
        )}

        <BiMenuAltLeft
          className="menu_bar"
          size={30}
          onClick={() => {
            setIsNavOpen(true);
          }}
        />
      </div>
    </div>
  );
};

export default Header;
