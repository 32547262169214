import { ethers } from "ethers";
import {
  dogeContractAddress,
  getDogeNFTContract,
} from "../configs/contract.config";

interface SupplyDetails {
  supply: string;
  maxSupply: string;
}

const mintDogeNFT = (amount: string): Promise<boolean> => {
  return new Promise(async (resolve, reject) => {
    try {
      const dogeNFT = getDogeNFTContract(dogeContractAddress);
      const price = await dogeNFT.price();
      const priceValue = Number(price.toString()) / Math.pow(10, 18);
      const priceToSell = Number(amount) * priceValue;
      console.log(priceValue, priceToSell);
      await dogeNFT.mint(BigInt(amount), {
        value: ethers.utils.parseEther(priceToSell.toString()),
      });
      resolve(true);
    } catch (error) {
      //   console.log(error);
      reject(error);
    }
  });
};

const getDogeNFTDetails = (): Promise<SupplyDetails> => {
  return new Promise(async (resolve, reject) => {
    try {
      const dogeNFT = getDogeNFTContract(dogeContractAddress);
      const supply = dogeNFT.totalSupply();
      const maxSupply = dogeNFT.maxSupply();

      const supplyDetails = await Promise.all([supply, maxSupply]);
      const results = {
        supply: supplyDetails[0].toString(),
        maxSupply: supplyDetails[1].toString(),
      };

      resolve(results);
    } catch (error) {
      const results = {
        supply: "0",
        maxSupply: "0",
      };

      resolve(results);
      console.log(error);
    }
  });
};

export { mintDogeNFT, getDogeNFTDetails };
