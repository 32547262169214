import "./index.css";
import makeBlockie from "ethereum-blockies-base64";
import { Link, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "../../context/context";

interface INFTCardProp {
  nftName?: string;
  isNftInMarketplace?: boolean;
  nftImage?: string;
  nftAnimationUrl?: string;
  nftPrice?: number;
  nftOwnerImg?: string;
  nftOwnerDisplayName?: string;
  nftOwnerAddress: string;
  nftContractAddress?: string;
  nftTokenId?: string;
  currentPage?: string;
}

const NFTCard = ({
  isNftInMarketplace,
  nftOwnerDisplayName,
  nftName,
  nftOwnerImg,
  nftOwnerAddress,
  nftImage,
  nftContractAddress,
  nftAnimationUrl,
  nftTokenId,
  nftPrice,
  currentPage,
}: INFTCardProp) => {
  const router = useNavigate();

  const {
    userAddress,
    ethereumPrice,
    explorePageCacheTrack,
    setExplorePageCacheTrack,
  } = useContext(AppContext);

  const handleCardClicked = () => {
    if (currentPage === "explore") {
      const currentExplorePageData = explorePageCacheTrack;
      if (currentExplorePageData !== null) {
        currentExplorePageData.positionInPage =
          document.documentElement.offsetHeight;
        setExplorePageCacheTrack(currentExplorePageData);
      }
    }
  };

  return (
    <div
      className="nft_wrap_card"
      onClick={() => {
        router(`/nft-item/${nftContractAddress}/${nftTokenId}`);
      }}
    >
      <div className="nft_image_view">
        {nftImage !== undefined && nftImage.trim() !== "" ? (
          <img
            src={nftImage}
            style={{
              padding: "20px",
            }}
            alt=""
          />
        ) : (
          <video autoPlay={false} controls={true} src={nftAnimationUrl}></video>
        )}
      </div>
      <Link
        style={{ textDecoration: "none" }}
        onClick={() => handleCardClicked()}
        to={`/nft-item/${nftContractAddress}/${nftTokenId}`}
      >
        <p className="nft_name">{nftName}</p>
      </Link>
      {isNftInMarketplace === true ? (
        <div className="nft_creator_section">
          <div className="creator_image_div">
            <img
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (userAddress === nftOwnerAddress) {
                  router("/profile");
                } else {
                  router(`/profile/${nftOwnerAddress}`);
                }
              }}
              src={
                nftOwnerImg?.trim() !== ""
                  ? nftOwnerImg
                  : makeBlockie(nftOwnerAddress)
              }
              alt=""
            />
          </div>

          <div className="creator_name_details">
            <p>Owner</p>
            <b
              onClick={() => {
                if (userAddress === nftOwnerAddress) {
                  router("/profile");
                } else {
                  router(`/profile/${nftOwnerAddress}`);
                }
              }}
              style={{ cursor: "pointer" }}
            >
              {nftOwnerDisplayName?.trim() !== ""
                ? nftOwnerDisplayName
                : `${nftOwnerAddress.slice(0, 5)}...${nftOwnerAddress.slice(
                    nftOwnerAddress.length - 4,
                    nftOwnerAddress.length
                  )}`}
            </b>
          </div>
          <div className="network_tag">
            <p>WDoge</p>
          </div>
        </div>
      ) : (
        ""
      )}

      {/* {
                isNftInMarketplace && nftPrice !== 0 ? <hr  className='horizontal_line' >

                </hr> : ""
            }
            */}
      <br />
      {isNftInMarketplace === true && nftPrice !== 0 ? (
        <div className="price_section">
          <p>Price</p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: "-20px",
            }}
          >
            <b> {nftPrice} wDoge </b>{" "}
            <p style={{ marginLeft: "10px" }}>
              ${(Number(nftPrice) * ethereumPrice).toLocaleString("en-US")}
            </p>
          </div>
        </div>
      ) : (
        ""
      )}
      <button
        className={"viewMoreButton"}
        onClick={() => {
          router(`/nft-item/${nftContractAddress}/${nftTokenId}`);
        }}
      >
        Buy Now
      </button>
    </div>
  );
};

export default NFTCard;
