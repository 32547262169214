import LoaderImage from '../../assets/loader.gif'
import styles from './loader.module.css'
const Loader  = ()=>{
    return(
        <div className={styles.loaderImage} >
        <img src={LoaderImage} alt="" />
</div>
    )
}


export default Loader