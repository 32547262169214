import type{IAuthTypeRes, ICreateUserAcccountRes, IGetProfileRes, IUpdateUserProfileRes, VagueResponse} from '../../types/dto/response'
import type { IAuthType, ICreateUserAcccountReq, IGetProfileReq, IUpdateUserProfileReq } from '../../types/dto/requests'
import { API_URL } from '../../constants/main'

const confirmUserAccountCreated = async(userAddress:string):Promise<IGetProfileRes>=>{
    return new Promise(async(resolve, reject)=>{
        const requestBody:IGetProfileReq = {
            userAddress : userAddress
       }
       try{
        const userProfileReq = await fetch(`${API_URL}/user/getProfile`, {
            method : 'POST',
            headers:{
                'Content-type' : "application/json"
            },
            body:JSON.stringify(requestBody)
        })
 
        const userProfileParse:IGetProfileRes = await userProfileReq.json()
        resolve(userProfileParse)
       }
       catch(error){
           const checkUserProfile = {
               success : false,
               reason : "An error occured",
           }   
           reject(checkUserProfile)
       }
    })
    
}

const loginUser = async(userAddress:string):Promise<IAuthTypeRes>=>{
    return new Promise(async (resolve,reject)=>{
        const requestBody:IAuthType = {
            userAddress : userAddress
        }
        try{
            const loginRequest = await fetch(`${API_URL}/user/login`, {
                method : 'POST',
                headers:{
                    'Content-Type' : 'application/json'
                },
                credentials :'include',
                body : JSON.stringify(requestBody)
            })
    
            const loginResponseParse =  await loginRequest.json()
    
            resolve(loginResponseParse)
        }
       catch(error){
           const loginResponse:IAuthTypeRes = {
               data : "",
               success : false,
               reason : "An Error Occured"
           }

           reject(loginResponse)
       }

    })
}

const logoutUser = async(userAddress:string):Promise<IAuthTypeRes>=>{
    return new Promise(async (resolve,reject)=>{
        const requestBody:IAuthType = {
            userAddress : userAddress
        }
        const loginRequest = await fetch(`${API_URL}/user/logout`, {
            method : 'POST',
            headers:{
                'Content-Type' : 'application/json'
            },
            credentials :'include',
            body : JSON.stringify(requestBody)
        })

        const loginResponseParse =  await loginRequest.json()

        resolve(loginResponseParse)

    })
}

const createUserAccount =   (userAddress : string):Promise<ICreateUserAcccountRes>=>{

    return new Promise(async(resolve,reject)=>{
        const userCreateReq:ICreateUserAcccountReq = {
            userAddress : userAddress,
            avatar : "",
            bioText : "",
            ownedNfts : [],
            profileCover : "",
            userName : ""
        } 
        const createUserAccountReq = await fetch(`${API_URL}/user/create`, {
            method : 'POST',
            headers : {
                'Content-type' : 'application/json'
            },
    
            body:JSON.stringify(userCreateReq)
        })

        const createUserAccountRes:ICreateUserAcccountRes =  await createUserAccountReq.json()
        resolve(createUserAccountRes)
    })
    
    
}

const getUserProfile = (userAddress:string):Promise<IGetProfileRes>=>{
    return new Promise(async(resolve, reject)=>{
        const requestBody:IGetProfileReq = {
            userAddress : userAddress
       }
       const userProfileReq = await fetch(`${API_URL}/user/getProfile`, {
           method : 'POST',
           headers:{
               'Content-type' : "application/json"
           },
           body:JSON.stringify(requestBody)
       })

       const userProfileParse:IGetProfileRes = await userProfileReq.json()
       resolve(userProfileParse)
    })
    
}

const updateUserProfile = async(profileLink:string, coverLink:string, displayName:string, bio:string,userAddress : string, emailAddress : string)=>{

    return new Promise(async (resolve,reject)=>{

        const userUpdateReqObject:IUpdateUserProfileReq = {
            avatar : profileLink,
            bioText : bio,
            profileCover : coverLink,
            userAddress : userAddress,
            userName : displayName,
            emailAddress : emailAddress
        }

        const updateUserProfileReq = await fetch(`${API_URL}/user/updateProfile`, {
            method : 'POST',
            headers:{
                'Content-type' : 'application/json'
            },
            credentials :'include',
            body : JSON.stringify(userUpdateReqObject)
        })

        const updateUserProfileRes:IUpdateUserProfileRes = await updateUserProfileReq.json()
        resolve(updateUserProfileRes)
    })
}

const getUserAuthStatus = ():Promise<VagueResponse>  =>{
    return new Promise(async(resolve,reject)=>{
        const fetchApi  =  await fetch(`${API_URL}/user/getAuthStatus`, {
            headers: {
                'Content-Type' : 'application/json'
            },
            credentials : 'include'
        })
        const responseData:VagueResponse  = await fetchApi.json()
        resolve(responseData)
    })
} 

export {confirmUserAccountCreated, createUserAccount, getUserProfile,updateUserProfile, loginUser, logoutUser, getUserAuthStatus}