import { getNFTContract, nftMarketContractAddress } from "../configs/contract.config"

const getMarketPlaceApprovalStatus = (contractAddress:string, tokenId:number):Promise<boolean>=>{
    return new Promise(async (resolve,reject)=>{
        const nftContract = getNFTContract(contractAddress)
        const currentApprovedAddress = await nftContract.getApproved(tokenId.toString())
        const isMarketPlaceApproved = currentApprovedAddress === nftMarketContractAddress;
        resolve(isMarketPlaceApproved)
    })
}

const approveMarketPlaceToTransferNFT = (contractAddress :string, tokenId:number):Promise<boolean>=>{
    return new Promise(async(resolve,reject)=>{
        const nftContract = getNFTContract(contractAddress)
        try{
            const approveContractCall =  await nftContract.approve(nftMarketContractAddress, tokenId)
            await approveContractCall.wait()
            resolve(true)
        }catch(e){
            console.log(e)
            reject(false)
        }
        
    })
}

export {getMarketPlaceApprovalStatus, approveMarketPlaceToTransferNFT}