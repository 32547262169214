import { useContext, useEffect, useState } from "react";
import styles from "./index.module.css";
import { BsPatchCheckFill } from "react-icons/bs";
import Countdown from "react-countdown";
import moment from "moment";
import WaifuElf from "../../assets/animation.gif";
import CountDownRender from "../CountDownRender";
import { AppContext } from "../../context/context";
import {
  getDogeNFTDetails,
  mintDogeNFT,
} from "../../smartContractFunctions/mintNft";
import { toast } from "react-toastify";
export function HeroDrops() {
  const { userAddress } = useContext(AppContext);

  const momentParser = moment(1661367600000);

  const [numberToMint, setNumberToMint] = useState(0);
  const [supply, setSupply] = useState("0");
  const [totalSupply, setTotalSupply] = useState("6969");

  useEffect(() => {
    getNFTDetails();
  }, []);

  const handleMintNft = async () => {
    if (numberToMint === 0) {
      toast.error("You need to mint at least 1 NFT");
      return;
    }
    const mintingToast = toast.loading("Minting....");
    try {
      const hasMinted = await mintDogeNFT(numberToMint.toString());
      console.log(hasMinted);
      if (hasMinted === true) {
        toast.update(mintingToast, {
          render: "Minted NFTs Successfully",
          isLoading: false,
          type: "success",
          autoClose: 1200,
        });
      }
    } catch (error: any) {
      console.log(error, "Error ");
      let errorMessage = "";
      if (error.data === undefined) {
        errorMessage = error.message;
      } else {
        errorMessage = error.data.message;
      }
      toast.update(mintingToast, {
        render: errorMessage,
        isLoading: false,
        type: "error",
        autoClose: 1200,
      });
    }
  };

  const getNFTDetails = async () => {
    const supplyDetails = await getDogeNFTDetails();
    console.log(supplyDetails);
    setSupply(supplyDetails.supply);
    // setTotalSupply(supplyDetails.maxSupply);
  };

  return (
    <div className={styles.projectSection}>
      <img className={styles.mainProjectImg} src={WaifuElf} alt="" />
      <div className={styles.projectInfo}>
        <div className={styles.mainWrap}>
          <h3>Sea Doges</h3>
          <div className={styles.profileSection}>
            <div>
              <img src={WaifuElf} alt="" />
              <BsPatchCheckFill
                style={{
                  position: "relative",
                  left: "-10px",
                }}
                color={"green"}
                size={15}
              />
            </div>
            <div className={styles.projectNameDetails}>
              <h4>DogeSea</h4>
              <p>Visit Website</p>
            </div>
          </div>

          <div className={styles.mintDetails}>
            <div className={styles.mintPriceDetails}>
              <p>Public Price</p>
              <h2 className={styles.publicPrice}>99 wDoge </h2>
              {/* <p>Sea Dogs Price : 80 wDoge </p> */}
              {}
              <div className={styles.progressBar}>
                <div
                  className={styles.innerBar}
                  style={{
                    width: `${(Number(supply) / Number(totalSupply)) * 100}%`,
                  }}
                ></div>
              </div>
              <p>
                Minted : {Number(supply).toLocaleString("en-US")} / Supply :{" "}
                {Number(totalSupply).toLocaleString("en-US")}{" "}
              </p>

              <div className={styles.mintCounterSection}>
                <div
                  className={styles.countButtons}
                  onClick={() => {
                    setNumberToMint((value) => {
                      if (value > 0) {
                        return value - 1;
                      } else {
                        return 0;
                      }
                    });
                  }}
                >
                  {" "}
                  <p>-</p>{" "}
                </div>
                <div className={styles.countHub}>
                  <p>{numberToMint}</p>
                </div>
                <div
                  className={styles.countButtons}
                  onClick={() => {
                    setNumberToMint((value) => {
                      return value + 1;
                    });
                  }}
                >
                  {" "}
                  <p>+</p>{" "}
                </div>
              </div>
              <br />
              <button
                className={styles.blueWhiteButton}
                onClick={handleMintNft}
              >
                {" "}
                Mint NFT{" "}
              </button>
            </div>
            <div className={styles.verticalDivider}></div>
            <div className={styles.mintPriceDetails}>
              <p>Drop Starts in </p>
              <Countdown date={1661367600000} renderer={CountDownRender} />

              <p>
                {momentParser.local().format("dddd, MMMM Do YYYY, h:mm:ss a")}
              </p>

              <br />

              {/* <button className={styles.blueWhiteButtonMob}> View Drop </button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
